import { Consultant } from "@/models/Consultant";
import { Leader } from "@/models/Leader";
import { NisConsultant } from "@/models/NisConsultant";
import store from "@/store";
import { MonthKey, dateKey } from "@/types/DateKey";
import { dateSpan, isInSpan, monthAsDateSpan } from "@/types/DateSpan";
import { getFirstDaysAfterInternship } from "./getFirstDaysAfterInternship";

export type MonthlyEmploymentCounts = {
  month: Date;
  consultants: number; // includes those with non billable (formerly in-house) assignments
  interns: number;
  internsNames: string[];
  leaders: number;
  nisConsultants: number;
  leaderAndNis: number;
};

export function calculateNumberOfEmployees(
  month: MonthKey,
  corporationId: number,
  consultantGroupId?: number,
  currencyId?: number,
  customerId?: number,
  options?: {
    includeInternships: boolean;
    includeLeaders: boolean;
    includeNis: boolean;
  }
): MonthlyEmploymentCounts {
  const {
    consultantData,
    consultantGroupData,
    leaderData,
    locationData,
    nisConsultantData,
    nisConsultantGroupData,
  } = store.state;

  const lastDayInMonth = monthAsDateSpan(month).end;

  function filterEmployee(employee: Leader | Consultant | NisConsultant) {
    if (employee.endDate == null)
      return dateKey(employee.startDate) <= lastDayInMonth;
    const employedSpan = dateSpan(employee.startDate, employee.endDate);
    return isInSpan(lastDayInMonth, employedSpan);
  }

  let consultants = consultantData.rows.filter(filterEmployee);
  let leaders = options?.includeLeaders
    ? leaderData.rows.filter(filterEmployee)
    : [];
  let nisConsultants =
    options?.includeNis && (!consultantGroupId || consultantGroupId < 1)
      ? nisConsultantData.rows.filter(filterEmployee)
      : [];

  if (consultantGroupId && consultantGroupId > 0) {
    consultants = consultants.filter(
      (consultant) => consultant.consultantGroupId == consultantGroupId
    );
    if (options?.includeLeaders) {
      const { firstLeaderId, secondLeaderId } =
        consultantGroupData.findById(consultantGroupId);
      leaders = leaders.filter(
        (l) => l.leaderId === firstLeaderId || l.leaderId === secondLeaderId
      );
    }
  } else if (corporationId > 0) {
    const consultantGroupIds = consultantGroupData.findIds(
      "corporationId",
      corporationId
    );
    consultants = consultants.filter((consultant) =>
      consultantGroupIds.has(consultant.consultantGroupId)
    );
    if (options?.includeLeaders) {
      const locationIds = locationData.findIds("corporationId", corporationId);
      leaders = leaders.filter((l) => locationIds.has(l.locationId));
    }
    if (options?.includeNis) {
      const locationIds = locationData.findIds("corporationId", corporationId);
      const allLeaderIds = leaderData.findWithValuesInSetIds(
        "locationId",
        locationIds
      );
      const nisConsultantGroupIds =
        nisConsultantGroupData.findWithValuesInSetIds("leaderId", allLeaderIds);
      nisConsultants = nisConsultants.filter((c) =>
        nisConsultantGroupIds.has(c.nisConsultantGroupId)
      );
    }
  }

  const firstDayAfterInternshipByConsultantId = getFirstDaysAfterInternship();

  let numberOfConsultants = 0;
  let interns = 0;
  const internsNames = new Array<string>();

  for (const c of consultants) {
    const startDate = firstDayAfterInternshipByConsultantId.get(c.consultantId);
    if (startDate === undefined || startDate <= lastDayInMonth) {
      numberOfConsultants++;
    } else if (options?.includeInternships) {
      interns++;
      internsNames.push(c.getName());
    }
  }

  let leaderAndNisUserIds: number[] = [];
  if (options?.includeLeaders && options.includeNis) {
    leaderAndNisUserIds = leaders
      .filter((l) => nisConsultants.some((n) => l.userId === n.userId))
      .map((l) => l.userId);
    leaders = leaders.filter((l) => !leaderAndNisUserIds.includes(l.userId));
    nisConsultants = nisConsultants.filter(
      (n) => !leaderAndNisUserIds.includes(n.userId)
    );
  }

  return {
    month: new Date(month),
    consultants: numberOfConsultants,
    interns,
    internsNames,
    leaders: leaders.length,
    nisConsultants: nisConsultants.length,
    leaderAndNis: leaderAndNisUserIds.length,
  };
}
