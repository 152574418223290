import { LOCALE } from "@/Constant";
import FileFolder from "@/components/enum/FileFolder";
import { Assignment } from "@/models/Assignment";
import { Currency } from "@/models/Currency";
import { ContractPhase, phaseName } from "@/models/enum/ContractPhase";
import { AssignmentData } from "@/store/data/AssignmentData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { CurrencyData } from "@/store/data/CurrencyData";
import FileData from "@/store/data/FileData";
import { TaskmasterData } from "@/store/data/TaskmasterData";
import { CssColor } from "@/types/CssColor";
import { container } from "tsyringe";
import { ReportEntry } from "./ReportEntry";
import { DiagramColors } from "./enum/DiagramColors";
import { HtmlSummaryBuilder } from "./util/HtmlSummaryBuilder";

const darkBackgrounds = new Set<CssColor>([
  DiagramColors.SIGNED_ASSIGNMENT,
  DiagramColors.ABSENCE,
  DiagramColors.NON_BILLABLE,
  DiagramColors.PROBABLE_ASSIGNMENT,
]);

export class CoveragePeriodReportEntry extends ReportEntry {
  consultant: string;
  taskmaster: string;
  customer: string;
  phase: ContractPhase;
  extension: number;
  assignmentId: number;
  assignmentName: string;
  hourlyRate: number;
  coverage: number;
  consultantEmploymentRate: number;
  customerId: number;
  taskmasterId: number;
  service: string;
  serviceId: number;
  isOverlap: boolean;
  hasOverlap: boolean;
  currency: Currency;
  assignmentData: AssignmentData;
  filesAvailable: number;
  urlsAvailable: number;

  public warningMessage: string;

  get isInternship(): boolean {
    return this.phase === ContractPhase.INTERNSHIP;
  }

  get isNonBillable(): boolean {
    return this.phase === ContractPhase.NON_BILLABLE;
  }

  constructor(coveragePeriod: Assignment, reportIndex: number) {
    super(
      coveragePeriod.getId(),
      coveragePeriod.consultantId,
      reportIndex,
      new Date(coveragePeriod.startDate),
      new Date(coveragePeriod.endDate)
    );
    this.extension = coveragePeriod.extension;
    this.consultant = `${coveragePeriod.firstname} ${coveragePeriod.lastname}`;
    const consultantData = container.resolve(ConsultantData);
    const consultant = consultantData.findById(this.consultantId);
    this.consultantEmploymentRate = consultant.coverage || 100;
    this.taskmaster = coveragePeriod.taskmasterName;
    this.taskmasterId = coveragePeriod.taskmasterId;
    this.customer = coveragePeriod.customerName;
    const taskmasterData = container.resolve(TaskmasterData);
    const taskmaster = taskmasterData.findById(coveragePeriod.taskmasterId);
    this.customerId = taskmaster.customerId;
    this.assignmentId = coveragePeriod.assignmentId;
    this.assignmentName = coveragePeriod.name;
    this.service = coveragePeriod.serviceName;
    this.serviceId = coveragePeriod.serviceId;
    this.hourlyRate = coveragePeriod.hourlyRate;
    this.coverage = coveragePeriod.coverage;
    this.phase = coveragePeriod.phase;
    this.isOverlap = false;
    this.hasOverlap = false;

    const currencyData = container.resolve(CurrencyData);
    this.assignmentData = container.resolve(AssignmentData);
    const currencyId = coveragePeriod.currencyId;
    this.currency = currencyData.findById(currencyId);
    this.filesAvailable = 0;
    this.urlsAvailable = 0;
    this.warningMessage = "";
  }

  getColor(): CssColor {
    if (this.hasWarning()) {
      return DiagramColors.WARNING;
    }
    if (this.phase === ContractPhase.INTERNSHIP) {
      return DiagramColors.INTERNSHIP;
    }
    if (this.phase === ContractPhase.NON_BILLABLE) {
      return DiagramColors.NON_BILLABLE;
    }
    const probable = this.phase === ContractPhase.PROBABLE_ASSIGNMENT;
    const parttime = this.coverage < (this.consultantEmploymentRate || 100);
    if (parttime) {
      if (probable) {
        return DiagramColors.PROBABLE_PARTTIME_ASSIGNMENT;
      } else {
        return DiagramColors.PARTTIME_ASSIGNMENT;
      }
    } else if (probable) {
      return DiagramColors.PROBABLE_ASSIGNMENT;
    } else {
      return DiagramColors.SIGNED_ASSIGNMENT;
    }
  }

  getTextColor(): string {
    if (darkBackgrounds.has(this.getColor())) {
      return DiagramColors.WHITE;
    } else {
      return DiagramColors.BLACK;
    }
  }

  getRowText(): string {
    if (this.filesAvailable == 0) return this.customer;
    else return this.customer + "*";
  }

  getSummarySize(): number {
    let size = 9;
    if (this.endDate != null) size++;
    if (this.phase != ContractPhase.INTERNSHIP) size++;
    return size;
  }

  public hasWarning(): boolean {
    // Warning checks
    // When adding new checks change this to if(this.assignmentExtentTooBig()) return true; and continue with else if's for the new checks
    return this.assignmentExtentTooBig();
  }

  private assignmentExtentTooBig(): boolean {
    this.warningMessage =
      'The assingnment "' +
      this.assignmentName +
      '" has an extent of ' +
      this.coverage +
      '% while the assignee "' +
      this.consultant +
      '" only has an employment rate of ' +
      this.consultantEmploymentRate +
      "%";
    return this.consultantEmploymentRate < this.coverage;
  }

  getSummaryHtml(): string {
    const builder = new HtmlSummaryBuilder()
      .addHeader(this.assignmentName)
      .addField("Consultant", this.consultant)
      .addField("Service", this.service)
      .addField("Customer", this.customer)
      .addField("Contact", this.taskmaster)
      .addField("Phase", phaseName(this.phase))
      .addField("Extent", this.coverage.toString().concat("%"))
      .addField("Start", this.startDate.toLocaleDateString(LOCALE));

    if (this.endDate != null) {
      builder.addField("End", this.endDate.toLocaleDateString(LOCALE));
    }

    if (
      this.phase !== ContractPhase.INTERNSHIP &&
      this.phase !== ContractPhase.NON_BILLABLE
    ) {
      builder.addField("Hourly rate", this.currency.symbolize(this.hourlyRate));
    }
    if (this.extension > 0) {
      builder.addField("Extension", this.extension.toString());
    }

    if (this.filesAvailable > 0) {
      builder.addField("Files available", this.filesAvailable.toString());
    }
    if (this.urlsAvailable > 0) {
      builder.addField("URLs available", this.urlsAvailable.toString());
    }

    if (this.hasWarning()) {
      return (
        builder.toString() + "<b> Warning! " + this.warningMessage + "</b>"
      );
    }
    return builder.toString();
  }

  async setFilesAvailable(): Promise<void> {
    await container
      .resolve(FileData)
      .getFileCount(FileFolder.Assignment, this.internalId)
      .then((count) => (this.filesAvailable = count));
  }

  deleteEntry(): void {
    this.assignmentData.delete(this.assignmentId);
  }

  private isActiveDuring(date: Date): boolean {
    return (
      this.startDate <= date && (this.endDate == null || date <= this.endDate)
    );
  }

  private isWithinPeriod(startDate: Date, endDate: Date): boolean {
    return (
      this.startDate.getTime() <= endDate.getTime() &&
      // eslint-disable-next-line
      this.endDate!.getTime() >= startDate.getTime()
    );
  }

  isActiveInPeriod(startDate: Date, endDate: Date): boolean {
    return (
      this.isActiveDuring(startDate) ||
      this.isWithinPeriod(startDate, endDate) ||
      this.isActiveDuring(endDate)
    );
  }
}
