import { AbsenceCategoryData } from "@/store/data/AbsenceCategoryData";
import { AbsenceData } from "@/store/data/AbsenceData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import { CorporationData } from "@/store/data/CorporationData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Absence } from "../Absence";
import { AbsenceCategory } from "../AbsenceCategory";
import { Consultant } from "../Consultant";
import { AbsenceCategoryDisplayable } from "./AbsenceCategoryDisplayable";
import { ConsultantDisplayable } from "./ConsultantDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { ConditionalField } from "./fields/ConditionalField";
import { DateField } from "./fields/DateField";
import {
  DropdownField,
  DropdownFieldSelectionFilter,
} from "./fields/DropdownField";
import { PercentageField } from "./fields/PercentageField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldDateGreaterThan } from "./fields/validators/FieldDateGreaterThan";
import { FieldConsultantEmployed } from "./fields/validators/FieldEmployeeEmployed";
import { FieldNumberMinMax } from "./fields/validators/FieldPercentageMinMax";

export class AbsenceDisplayable extends DisplayableBase<Absence> {
  @ValidateNested()
  consultant: DropdownField<Consultant, ConsultantDisplayable>;
  @ValidateNested()
  absenceCategory: DropdownField<AbsenceCategory, AbsenceCategoryDisplayable>;
  @ValidateNested()
  @FieldNumberMinMax()
  coverage: PercentageField;
  @ValidateNested()
  @FieldConsultantEmployed("consultant")
  startDate: DateField;
  @ValidateNested()
  @FieldDateGreaterThan("startDate")
  @FieldConsultantEmployed("consultant")
  endDate: DateField;
  @ValidateNested()
  approved: ConditionalField;

  constructor(absence: Absence) {
    super();
    const absenceData = container.resolve(AbsenceData);
    const consultantData = container.resolve(ConsultantData);
    const absenceCategoryData = container.resolve(AbsenceCategoryData);
    const consultantGroupData = container.resolve(ConsultantGroupData);
    const corporationData = container.resolve(CorporationData);

    const { headers } = absenceData;
    const consultantName = `${absence.firstname} ${absence.lastname}`;
    this.consultant = new DropdownField(
      headers.consultant,
      consultantName,
      consultantData,
      new DropdownOption(absence.consultantId, consultantName),
      {
        selectionFilter: new DropdownFieldSelectionFilter(
          consultantGroupData,
          "consultantGroupId",
          "consultantGroupName",
          new DropdownFieldSelectionFilter(
            corporationData,
            "corporationId",
            "corporationName"
          )
        ),
      }
    );
    this.consultant.editImpossible = true;
    this.absenceCategory = new DropdownField(
      headers.absenceCategory,
      absence.absenceCategoryName,
      absenceCategoryData,
      new DropdownOption(absence.absenceCategoryId, absence.absenceCategoryName)
    );
    this.coverage = new PercentageField(headers.coverage, absence.coverage);
    this.startDate = new DateField(headers.startDate, absence.startDate);
    this.endDate = new DateField(headers.endDate, absence.endDate);
    this.approved = new ConditionalField(headers.approved, absence.approved, {
      enabledWhen: () => absence.requiredRequest,
      required: absence.requiredRequest,
    });
  }

  toModel(absence: Absence): void {
    absence.coverage = this.coverage.modelValue();
    absence.startDate = this.startDate.modelValue();
    absence.endDate = this.endDate.modelValue();
    absence.approved = this.approved.modelValue();
    absence.consultantId = this.consultant.selectedOption.id;
    absence.absenceCategoryId = this.absenceCategory.selectedOption.id;
  }
}
