import { TableHeaders } from "@/interfaces/TableHeaders";
import { NisConsultantDisplayable } from "@/models/displayable/NisConsultantDisplayable";

export class NisConsultantHeaders
  implements TableHeaders<NisConsultantDisplayable>
{
  public readonly name = "Name";
  public readonly urlName = "Consultant / Profile";
  public readonly group = "Group";
  public readonly corporation = "Company";
  public readonly employmentStart = "Employment Start";
  public readonly employmentEnd = "Employment End";
}
