import { PriorityData } from "@/store/data/PriorityData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Priority } from "../Priority";
import { DisplayableBase } from "./DisplayableBase";
import { NumberField } from "./fields/NumberField";
import { UniqueTextField } from "./fields/UniqueTextField";
import { FieldNumberMinMax } from "./fields/validators/FieldPercentageMinMax";
import { FieldUniqueText } from "./fields/validators/FieldUniqueText";

export class PriorityDisplayable extends DisplayableBase<Priority> {
  @ValidateNested()
  @FieldUniqueText<Priority, PriorityDisplayable>()
  name: UniqueTextField<Priority, PriorityDisplayable>;
  @ValidateNested()
  @FieldNumberMinMax()
  order: NumberField;

  constructor(priority: Priority) {
    super();
    const priorityData = container.resolve(PriorityData);
    const { headers } = priorityData;

    this.name = new UniqueTextField<Priority, PriorityDisplayable>(
      headers.name,
      priority.name,
      priorityData
    );
    this.order = new NumberField(headers.order, priority.getOrderNumber());
  }

  toModel(priority: Priority): void {
    priority.name = this.name.modelValue();
    priority.order = Number(this.order.modelValue());
  }
}
