import { IsInt, ValidationArguments } from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { NumberError } from "./error/NumberError";

export class PercentageField extends DisplayableField<string, number> {
  @IsInt({
    message: (args: ValidationArguments) =>
      (args.object as PercentageField)._error.valueError(args.value),
  })
  protected _value: number;
  protected _min: number;
  protected _max: number;

  get value(): string {
    return this._value.toString();
  }

  set value(input: string) {
    this._value = parseInt(input);
  }

  modelValue(): number {
    return this._value;
  }

  get min(): number {
    return this._min;
  }

  get max(): number {
    return this._max;
  }

  constructor(header: string, data: number, options?: DisplayableFieldOptions) {
    super(header, new NumberError(header), FieldType.PERCENTAGE, options);
    this._value = data;
    this._suffix ??= "%";
    this._min = options?.minNumericValue ?? 1;
    this._max = options?.maxNumericValue ?? 100;
  }
}
