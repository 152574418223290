import FileFolder from "@/components/enum/FileFolder";
import { Named } from "@/interfaces/Named";
import { Absence } from "@/models/Absence";
import { Assignment } from "@/models/Assignment";
import { Consultant } from "@/models/Consultant";
import { ContractPhase } from "@/models/enum/ContractPhase";
import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import FileData from "@/store/data/FileData";
import { LocationData } from "@/store/data/LocationData";
import { ReportSettingsData } from "@/store/data/ReportSettingsData";
import { DateUtils } from "@/util/DateUtils";
import { container } from "tsyringe";
import { AbsenceReportEntry } from "./AbsenceReportEntry";
import { CoveragePeriodReportEntry } from "./CoveragePeriodReportEntry";
import { OverlapPeriodReportEntry } from "./OverlapPeriodReportEntry";
import { PeriodReportEntry } from "./PeriodReportEntry";
import { ReportEntry } from "./ReportEntry";
import { DAY_MILLISECONDS } from "./constant/Times";
import { DiagramColors } from "./enum/DiagramColors";
import { Period } from "./util/Period";

export class ConsultantReportEntry extends ReportEntry implements Named {
  private _name: string;
  private _consultantStartDate: Date;
  private _consultantEndDate: Date | null;
  private _consultantCoverage: number;
  private _userId: number;
  private _keyCompetencies: string | null;
  private _education: string | null;
  private _certificates: string | null;
  private _competencies: string | null;
  private _services: string | null;
  private _corporation: string;
  private _consultantGroupId: number;
  private _consultantGroupName: string;
  private _consultantGroupLocationId: number;
  private _consultantGroupLocationName: string;
  private _currentServiceId: number | null;
  private _currentServiceName: string | null;
  private _currentCustomerId: number | null;
  private _currentCustomerName: string | null;
  private _coveragePeriodReportEntries: CoveragePeriodReportEntry[];
  private _absenceReportEntries: AbsenceReportEntry[];
  private _filesAvailable?: number;
  private _urlsAvailable?: number;
  public currentCoworkers: number | null;
  public sortingGroup: string | null = null;
  public sortingGroupN: number | null = null;
  public sortingGroupIndex: number | null = null;

  constructor(
    consultant: Consultant,
    keyCompetencies: string | null,
    competencies: string | null,
    education: string | null,
    certificates: string | null,
    services: string | null,
    coveragePeriods: Assignment[],
    absences: Absence[],
    reportIndex = 0
  ) {
    super(
      consultant.getId(),
      consultant.consultantId,
      reportIndex,
      new Date(consultant.startDate),
      consultant.endDate ? new Date(consultant.endDate) : null
    );
    this._name = `${consultant.firstname} ${consultant.lastname}`;
    this._consultantStartDate = consultant.getStartDate();
    this._consultantEndDate = consultant.getEndDate();
    this._consultantCoverage = consultant.coverage;
    this._userId = consultant.userId;
    this._keyCompetencies = keyCompetencies;
    this._education = education;
    this._certificates = certificates;
    this._competencies = competencies;
    this._services = services;
    this._corporation = consultant.corporationName;
    this._consultantGroupId = consultant.consultantGroupId;
    this._consultantGroupName = consultant.consultantGroupName;
    const consultantGroupData = container.resolve(ConsultantGroupData);
    const consultantGroup = consultantGroupData.findById(
      this._consultantGroupId
    );
    const locationData = container.resolve(LocationData);
    const location = locationData.findById(consultantGroup.locationId);
    this._consultantGroupLocationId = location.locationId;
    this._consultantGroupLocationName = location.name;
    this._coveragePeriodReportEntries =
      this.filterCoveragePeriodsById(coveragePeriods);
    this._absenceReportEntries = this.filterAbsencesById(absences);

    this.currentCoworkers = null;

    //Should be last: computed properties need all their data
    const a = this.getCurrentCustomer();
    this._currentCustomerId = a?.customerId ?? null;
    this._currentCustomerName = a?.customer ?? null;
    this._currentServiceId = a?.serviceId ?? null;
    this._currentServiceName = a?.service ?? null;
  }

  // Sort last report entry first
  private static reportEntryComparator = (c1: ReportEntry, c2: ReportEntry) => {
    if (c1.startDate > c2.startDate) return 1;
    if (c1.startDate < c2.startDate) return -1;
    return 0;
  };
  // Sort last report entry first based on end date
  private static reportEntryEndDateComparator = (
    c1: ReportEntry,
    c2: ReportEntry
  ) => {
    if (c1.endDate && c2.endDate) {
      if (c1.endDate > c2.endDate) return 1;
      if (c1.endDate < c2.endDate) return -1;
    }
    return 0;
  };

  get name(): string {
    return this._name + (this.filesAvailable > 0 ? "*" : "");
  }

  get userId(): number {
    return this._userId;
  }

  get certificateDescription(): string {
    return this._certificates ?? "None";
  }

  get keyCompetenciesDescription(): string {
    return this._keyCompetencies ?? "None";
  }

  get educationDescription(): string {
    return this._education ?? "None";
  }

  get competenciesDescription(): string {
    return this._competencies ?? "None";
  }

  get servicesDescription(): string {
    return this._services ?? "None";
  }

  get currentServiceId(): number | null {
    return this._currentServiceId;
  }

  get currentServiceName(): string | null {
    return this._currentServiceName;
  }

  get currentCustomerId(): number | null {
    return this._currentCustomerId;
  }

  get consultantEmploymentRate(): number {
    return this._consultantCoverage;
  }

  get currentCustomerName(): string | null {
    return this._currentCustomerName;
  }

  get corporation(): string {
    return this._corporation;
  }

  get consultantGroupId(): number {
    return this._consultantGroupId;
  }

  get consultantGroupName(): string {
    return this._consultantGroupName;
  }

  get consultantGroupLocationId(): number {
    return this._consultantGroupLocationId;
  }

  get consultantGroupLocationName(): string {
    return this._consultantGroupLocationName;
  }

  get coveragePeriodReportEntries(): CoveragePeriodReportEntry[] {
    return this._coveragePeriodReportEntries;
  }

  public get filesAvailable(): number {
    return this._filesAvailable || 0;
  }

  public set filesAvailable(count: number) {
    this._filesAvailable = count;
  }

  public get urlsAvailable(): number {
    return this._urlsAvailable || 0;
  }
  public set urlsAvailable(count: number) {
    this._urlsAvailable = count;
  }

  get reportIndex(): number {
    return super.reportIndex;
  }

  set reportIndex(reportIndex: number) {
    super.reportIndex = reportIndex;
    this._coveragePeriodReportEntries.forEach(
      (c) => (c.reportIndex = reportIndex)
    );
    this._absenceReportEntries.forEach((c) => (c.reportIndex = reportIndex));
  }

  async setFilesAvailable(): Promise<void> {
    await container
      .resolve(FileData)
      .getFileCount(FileFolder.Profile, this._userId)
      .then((count) => (this._filesAvailable = count));
  }

  getName(): string {
    return this.name;
  }

  getStartDate(): Date {
    return this.startDate;
  }

  getEndDate(): Date | null {
    return this.endDate;
  }

  getCurrentCoveragePeriods(
    startDate: Date,
    endDate: Date
  ): CoveragePeriodReportEntry[] {
    return this.getEntriesInTimespan(
      this._coveragePeriodReportEntries,
      startDate,
      endDate
    );
  }

  getCurrentAbsences(startDate: Date, endDate: Date): AbsenceReportEntry[] {
    const currentAbsences = this.getEntriesInTimespan(
      this._absenceReportEntries,
      startDate,
      endDate
    );
    return currentAbsences;
  }

  getColor(): string {
    return DiagramColors.UNOCCUPIED;
  }

  getTextColor(): string {
    return DiagramColors.BLACK;
  }

  getRowText(): string {
    return "";
  }

  getSummarySize(): number {
    return 0;
  }

  getSummaryHtml(): string {
    return "";
  }

  getCurrentCustomer(): CoveragePeriodReportEntry | null {
    const today = new Date();
    const isCurrentCoveragePeriod = (x: CoveragePeriodReportEntry) =>
      x.isInternship === false &&
      today >= x.startDate &&
      (x.endDate === null || today <= x.endDate);
    //Should at present just return one //TODO: take overlap into account, there may be more than one current customer
    const coverageEntries = this._coveragePeriodReportEntries.find(
      isCurrentCoveragePeriod
    );

    const mainCustomer = coverageEntries ?? null;
    return mainCustomer;
  }

  getSortedPeriods(startDate: Date, endDate: Date): Period[] {
    const currentSettledCoveragePeriods = this.getCurrentCoveragePeriods(
      startDate,
      endDate
    );

    const periods = Period.getPeriods(currentSettledCoveragePeriods).concat(
      this.absencePeriods(startDate, endDate)
    );

    return Period.sortPeriodsAsc(periods);
  }

  private absencePeriods(startDate: Date, endDate: Date) {
    return Period.getPeriods(this.getCurrentAbsences(startDate, endDate));
  }

  isTotallyAbsent(startDate: Date, endDate: Date): boolean {
    const absences = this.getCurrentAbsences(startDate, endDate);
    absences.sort(ConsultantReportEntry.reportEntryComparator);
    if (startDate < this._consultantStartDate)
      startDate = new Date(
        this._consultantStartDate.getFullYear(),
        this._consultantStartDate.getMonth(),
        this._consultantStartDate.getDate()
      );
    if (this._consultantEndDate && endDate > this._consultantEndDate)
      endDate = new Date(
        this._consultantEndDate.getFullYear(),
        this._consultantEndDate.getMonth(),
        this._consultantEndDate.getDate()
      );
    let firstDay = endDate;
    let lastDay = startDate;
    for (let i = 0; i < absences.length; i++) {
      //Only considers absences that have 100% extent
      if (absences[i].coverage == 100) {
        const start = absences[i].startDate;
        const end = absences[i].endDate ?? endDate;
        if (start > new Date(lastDay.getTime() + DAY_MILLISECONDS) && i > 0)
          return false;
        if (start < firstDay) firstDay = start;
        if (end > lastDay) lastDay = end;
      }
    }
    //Converts the dates to the same form as startDate and endDate
    firstDay = new Date(
      firstDay.getFullYear(),
      firstDay.getMonth(),
      firstDay.getDate()
    );
    lastDay = new Date(
      lastDay.getFullYear(),
      lastDay.getMonth(),
      lastDay.getDate()
    );
    return firstDay <= startDate && lastDay >= endDate;
  }

  hasInternship(startDate: Date, endDate: Date): boolean {
    return this.isContractPhaseLastOrCurrent(
      startDate,
      endDate,
      ContractPhase.INTERNSHIP
    );
  }

  hasNonBillable(startDate: Date, endDate: Date): boolean {
    return this.isContractPhaseLastOrCurrent(
      startDate,
      endDate,
      ContractPhase.NON_BILLABLE
    );
  }

  isContractPhaseLastOrCurrent(
    startDate: Date,
    endDate: Date,
    contractPhase: ContractPhase
  ) {
    const currentCoveragePeriods = this.getEntriesInTimespan(
      this.coveragePeriodReportEntries,
      startDate,
      endDate
    );
    if (currentCoveragePeriods.length == 0) {
      return false;
    }
    const lastEntry = currentCoveragePeriods[currentCoveragePeriods.length - 1];
    if (contractPhase == ContractPhase.INTERNSHIP) {
      return lastEntry.isInternship;
    } else if (contractPhase == ContractPhase.NON_BILLABLE) {
      return lastEntry.isNonBillable;
    }
    return false;
  }

  isPhaseInPeriod(
    phaseFilter: number,
    startDate: Date,
    endDate: Date
  ): boolean {
    return this._coveragePeriodReportEntries.some(
      (entry) =>
        entry.phase === phaseFilter &&
        entry.isActiveInPeriod(startDate, endDate)
    );
  }

  isServiceProviderInPeriod(
    serviceFilter: number,
    startDate: Date,
    endDate: Date
  ): boolean {
    return this._coveragePeriodReportEntries.some(
      (entry) =>
        entry.serviceId === serviceFilter &&
        entry.isActiveInPeriod(startDate, endDate)
    );
  }

  hasCustomer(customerFilter: number): boolean {
    return this._coveragePeriodReportEntries.some(
      (entry) => entry.customerId === customerFilter
    );
  }

  hasCustomerInPeriod(
    customerFilter: number,
    startDate: Date,
    endDate: Date
  ): boolean {
    return this._coveragePeriodReportEntries.some(
      (entry) =>
        entry.customerId === customerFilter &&
        entry.isActiveInPeriod(startDate, endDate)
    );
  }

  hasCustomerAndTaskmasterInPeriod(
    customerFilter: number,
    taskmasterFilter: number,
    startDate: Date,
    endDate: Date
  ): boolean {
    return this._coveragePeriodReportEntries.some(
      (entry) =>
        entry.customerId === customerFilter &&
        entry.taskmasterId === taskmasterFilter &&
        entry.isActiveInPeriod(startDate, endDate)
    );
  }

  hasAssignment(startDate: Date, endDate: Date): boolean {
    const currentCoveragePeriods = this.getCurrentCoveragePeriods(
      startDate,
      endDate
    );
    const periods = Period.getPeriods(currentCoveragePeriods);

    const endsDuringOrAfter = !this.lastEntryEndsBefore(periods, startDate);
    const startsDuringOrBefore = !this.firstEntryStartsAfter(periods, endDate);

    return !this.hasGaps(periods) && startsDuringOrBefore && endsDuringOrAfter;
  }

  isAvailable(startDate: Date, endDate: Date): boolean {
    // If a consultant has not started yet, they can still be available later.
    if (this._consultantStartDate > startDate) {
      startDate = this._consultantStartDate;
    }

    // if a consultant will leave the company they can still be available until that date.
    if (this._consultantEndDate != null && this._consultantEndDate < endDate) {
      endDate = this._consultantEndDate;
    }

    // Gets the entries and sorts the in reverse order
    const reportEntries = this.getCurrentCoveragePeriods(
      startDate,
      endDate
    ).filter(
      (entry) =>
        entry.phase != ContractPhase.PROBABLE_ASSIGNMENT &&
        entry.coverage >= this._consultantCoverage
    );
    reportEntries.sort(ConsultantReportEntry.reportEntryComparator);
    const absenceEntries = this.getCurrentAbsences(startDate, endDate);
    absenceEntries.sort(ConsultantReportEntry.reportEntryComparator);

    let isAvailable = true;
    // If a consultant´s last assignments or absenses ends after enddate of the chosen timespan, they are not available.
    for (const entries of [reportEntries, absenceEntries]) {
      if (entries.length == 0) {
        continue;
      }
      const lastEntry = entries[entries.length - 1];
      // If the absence extent is less than the consultant's employment rate, they can still be partly available
      if (lastEntry.coverage >= this._consultantCoverage) {
        if (lastEntry.endDate == null || lastEntry.endDate >= endDate) {
          isAvailable = false;
          break;
        }
      }
    }
    return isAvailable;
  }

  lastEntryEndsBefore(periods: Period[], endDate: Date): boolean {
    if (periods.length === 0) return true;

    const lastDate = Period.findLastDate(periods);
    if (lastDate === null) return false;

    return endDate > lastDate;
  }

  firstEntryStartsAfter(periods: Period[], startDate: Date): boolean {
    if (periods.length === 0) return true;

    const firstDate = Period.findFirstDate(periods);
    if (this.startDate > startDate) startDate = this.startDate;

    return firstDate > startDate;
  }

  static compareAvailability(
    c1: ConsultantReportEntry,
    c2: ConsultantReportEntry
  ): number {
    const dateUtils = container.resolve(DateUtils);
    const settingsData = container.resolve(ReportSettingsData);
    const options = settingsData.loadReportSettings();
    const period = {
      startDate: new Date( // Added to get the time to be at midnight to help with comparisons of dates
        new Date(options.startDate).getFullYear(),
        new Date(options.startDate).getMonth(),
        new Date(options.startDate).getDate()
      ),
      endDate: dateUtils.removeOneDayToDate(new Date(options.endDate)), // Needs to remove a day to account for the added day in assignmentoverview
    };
    // Gets the entries and sorts the in reverse order
    const c1ReportEntries = c1
      .getCurrentCoveragePeriods(period.startDate, period.endDate)
      .filter((e) => !(e instanceof OverlapPeriodReportEntry));
    c1ReportEntries.sort(ConsultantReportEntry.reportEntryEndDateComparator);

    const c1ReportEntriesLength = c1ReportEntries.length;
    const c1lastAssignment = c1ReportEntries[c1ReportEntriesLength - 1];

    const c2ReportEntries = c2
      .getCurrentCoveragePeriods(period.startDate, period.endDate)
      .filter((e) => !(e instanceof OverlapPeriodReportEntry));

    c2ReportEntries.sort(ConsultantReportEntry.reportEntryEndDateComparator);

    const c2ReportEntriesLength = c2ReportEntries.length;
    const c2lastAssignment = c2ReportEntries[c2ReportEntriesLength - 1];

    if (c1ReportEntriesLength === 0 && c2ReportEntriesLength === 0) {
      return c1.name.localeCompare(c2.name);
    }
    if (c1ReportEntriesLength > 0 && c2ReportEntriesLength == 0) {
      return 1;
    }

    if (c1ReportEntriesLength == 0 && c2ReportEntriesLength > 0) {
      return -1;
    }
    if (
      c1lastAssignment.endDate &&
      c2lastAssignment.endDate &&
      ((c1lastAssignment.endDate > period.endDate &&
        c2lastAssignment.endDate > period.endDate) ||
        c1lastAssignment.endDate.getTime() ===
          c2lastAssignment.endDate.getTime())
    ) {
      if (
        c1lastAssignment.phase === ContractPhase.ASSIGNMENT &&
        c2lastAssignment.phase !== ContractPhase.ASSIGNMENT
      ) {
        return 1;
      }
      if (
        c1lastAssignment.phase !== ContractPhase.ASSIGNMENT &&
        c2lastAssignment.phase === ContractPhase.ASSIGNMENT
      ) {
        return -1;
      }
      return c1.name.localeCompare(c2.name);
    }
    if (
      c1lastAssignment.endDate &&
      c2lastAssignment.endDate &&
      c1lastAssignment.endDate.getTime() !== c2lastAssignment.endDate.getTime()
    ) {
      return (
        c1lastAssignment?.endDate.getTime() -
        c2lastAssignment?.endDate.getTime()
      );
    }
    return c1.name.localeCompare(c2.name);
  }
  private filterCoveragePeriodsById(
    coveragePeriods: Assignment[]
  ): CoveragePeriodReportEntry[] {
    return coveragePeriods
      .filter((c) => c.consultantId === this.consultantId)
      .map((f) => new CoveragePeriodReportEntry(f, this.reportIndex));
  }

  private filterAbsencesById(absences: Absence[]): AbsenceReportEntry[] {
    return absences
      .filter((a) => a.consultantId === this.consultantId)
      .map((f) => new AbsenceReportEntry(f, this.reportIndex));
  }

  private getEntriesInTimespan<T extends PeriodReportEntry>(
    entries: T[],
    startDate: Date,
    endDate: Date
  ): T[] {
    const entriesInTimespan = entries
      .filter((entry) => this.isInTimespan(entry, startDate, endDate))
      .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
    if (entriesInTimespan[0] instanceof AbsenceReportEntry) {
      //For removing text on overlap absences
      this.checkOverlap(entriesInTimespan);

      return entriesInTimespan;
    }
    const checkedEntriesInTimespan = this.checkOverlap(entriesInTimespan);
    return checkedEntriesInTimespan;
  }

  private checkOverlap<T extends PeriodReportEntry>(entries: T[]) {
    const datesOfChange = this.uniqueSortedDatesOfChange(entries);

    const overlaps = <T[]>[];
    for (let i = 0; i < datesOfChange.length - 1; i++) {
      const startDate = datesOfChange[i];
      const endDate = datesOfChange[i + 1];
      const entriesInSpan = entries.filter((entry) =>
        this.isEntryCoveringSpan(entry, startDate, endDate)
      );
      if (entriesInSpan.length >= 2) {
        overlaps.push(
          this.generateOverlap(entriesInSpan, startDate, endDate) as T
        );
      }
    }

    return [...entries, ...overlaps];
  }

  private uniqueSortedDatesOfChange<T extends PeriodReportEntry>(entries: T[]) {
    const datesOfChange = <Date[]>[];
    entries.forEach((entry) => {
      datesOfChange.push(entry.startDate);
      if (entry.endDate) {
        datesOfChange.push(entry.endDate);
      }
    });
    const uniqueSortedDatesOfChange = datesOfChange
      .map((date) => date.getTime())
      .filter((item, index, arr) => arr.indexOf(item) === index)
      .sort()
      .map((timestamp) => new Date(timestamp));
    return uniqueSortedDatesOfChange;
  }

  private isEntryCoveringSpan<T extends PeriodReportEntry>(
    entry: T,
    startDate: Date,
    endDate: Date
  ) {
    const startsOnOrBefore = entry.startDate.getTime() <= startDate.getTime();
    const endsOnOrAfter =
      !entry.endDate || entry.endDate.getTime() >= endDate.getTime();
    return startsOnOrBefore && endsOnOrAfter;
  }

  private generateOverlap<T extends PeriodReportEntry>(
    members: T[],
    startDate: Date,
    endDate: Date
  ) {
    const overlapPeriod = new Assignment();
    const overlap = new OverlapPeriodReportEntry(
      overlapPeriod,
      0,
      members as CoveragePeriodReportEntry[]
    );
    overlap.startDate = startDate;
    overlap.endDate = endDate;
    overlap.consultant = members[0].consultant;
    overlap.customer = members
      .map((m) => (m as CoveragePeriodReportEntry).customer)
      .join(", ");
    overlap.reportIndex = members[0].reportIndex;
    overlap.isOverlap = true;
    overlap.coverage = members
      .map((m) => m.coverage)
      .reduce((total, current) => total + current);

    this.coveragePeriodReportEntries.forEach((v) => {
      v.hasOverlap = this.isInTimespan(v, startDate, endDate);
    });

    this._absenceReportEntries.forEach((v) => {
      v.hasOverlap = this.isInTimespan(v, startDate, endDate);
    });

    return overlap;
  }

  private isInTimespan(entry: ReportEntry, startDate: Date, endDate: Date) {
    const endsAfterStart = entry.endDate ? startDate <= entry.endDate : true;
    const startsBeforeEnd = entry.startDate < endDate;

    return startsBeforeEnd && endsAfterStart;
  }

  private hasGaps(periods: Period[]): boolean {
    if (periods.length >= 1) {
      return false;
    }

    Period.sortPeriodsAsc(periods);

    for (let i = 1; i < periods.length; i++) {
      const previousEntryEnd = periods[i - 1].endDate;
      const nextEntryStart = periods[i].startDate;
      if (previousEntryEnd !== null) {
        const previousEntryEndNextDay = new Date(previousEntryEnd);
        previousEntryEndNextDay.setDate(previousEntryEnd.getDate() + 1); // This is necessary in order to reflect the appearence of the report
        return previousEntryEndNextDay < nextEntryStart ? true : false;
      }
    }

    return false;
  }
}
