import { FieldType } from "./enum/FieldType";
import { VeaError } from "./error/VeaError";

export interface IDisplayableField {
  readonly header: string;
  readonly type: FieldType;
  readonly hideInTable: boolean;
  readonly hideInForm: boolean;
  readonly placeholder: string | undefined;
  readonly mandatory: boolean | undefined;
  readonly maxLength: number | undefined;
  readonly enabled: boolean;
  readonly prefix: string | undefined;
  readonly suffix: string | undefined;
  readonly value: unknown;
}

/**
 * Represents a generic displayable field. 'E' denotes the value type used externally
 * (in the view), while 'I' denotes the value type used internally (in the model).
 */
export abstract class DisplayableField<E, I> implements IDisplayableField {
  /**
   * We use E|I here because you may want to validate either
   * the internal OR the external value type.
   */
  private _header: string;
  protected abstract _value: E | I;
  protected _error: VeaError<E | I>;
  protected _type: FieldType;
  protected _hideInTable: boolean;
  protected _hideInForm: boolean;
  protected _placeholder: string | undefined;
  protected _mandatory: boolean | undefined;
  protected _maxLength: number | undefined;
  protected _enabledWhen?: () => boolean;
  protected _prefix: string | undefined;
  protected _suffix: string | undefined;
  abstract get value(): E;
  abstract set value(input: E);

  get header(): string {
    return this._header;
  }

  get type(): FieldType {
    return this._type;
  }

  get hideInTable(): boolean {
    return this._hideInTable;
  }

  get hideInForm(): boolean {
    return this._hideInForm;
  }

  set hideInForm(input: boolean) {
    this._hideInForm = input;
  }

  get error(): VeaError<E | I> {
    return this._error;
  }

  get placeholder(): string | undefined {
    return this._placeholder;
  }

  get mandatory(): boolean | undefined {
    return this._mandatory;
  }

  get maxLength(): number | undefined {
    return this._maxLength;
  }

  get enabled(): boolean {
    return this._enabledWhen ? this._enabledWhen() : true;
  }

  get prefix(): string | undefined {
    return this._prefix;
  }

  get suffix(): string | undefined {
    return this._suffix;
  }

  abstract modelValue(): I;

  constructor(
    header: string,
    error: VeaError<E | I>,
    type: FieldType,
    options?: DisplayableFieldOptions
  ) {
    this._header = header;
    this._error = error;
    this._type = type;
    this._hideInTable = options?.hideInTable ?? false;
    this._hideInForm = options?.hideInForm ?? false;
    this._placeholder = options?.placeholder;
    this._maxLength = options?.maxLength;
    this._enabledWhen = options?.enabledWhen;
    this._prefix = options?.prefix;
    this._suffix = options?.suffix;
  }
}

export class DisplayableFieldOptions {
  hideInTable?: boolean;
  hideInForm?: boolean;
  placeholder?: string;
  maxLength?: number;
  minNumericValue?: number;
  maxNumericValue?: number;
  enabledWhen?: () => boolean;
  prefix?: string;
  suffix?: string;
}
