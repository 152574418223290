import FileFolder from "@/components/enum/FileFolder";
import {
  createCertificateListWithDates,
  createCompetencyList,
  createServiceList,
} from "@/coveragePeriodReport/util/createConnectionEntries";
import { AbsenceData } from "@/store/data/AbsenceData";
import { AssignmentData } from "@/store/data/AssignmentData";
import { ConsultantData } from "@/store/data/ConsultantData";
import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import { CorporationData } from "@/store/data/CorporationData";
import FileData from "@/store/data/FileData";
import { LeaderData } from "@/store/data/LeaderData";
import { NisConsultantData } from "@/store/data/NisConsultantData";
import { UserData } from "@/store/data/UserData";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { Consultant } from "../Consultant";
import { ConsultantGroup } from "../ConsultantGroup";
import { User } from "../User";
import { ConsultantGroupDisplayable } from "./ConsultantGroupDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { UserDisplayable } from "./UserDisplayable";
import { DateField } from "./fields/DateField";
import {
  DropdownField,
  DropdownFieldSelectionFilter,
} from "./fields/DropdownField";
import { FilesField } from "./fields/FilesField";
import { FilesUrlField } from "./fields/FilesUrlField";
import { NullableDateField } from "./fields/NullableDateField";
import { PercentageField } from "./fields/PercentageField";
import { TableField } from "./fields/TableField";
import { TextAreaField } from "./fields/TextAreaField";
import { UrlField } from "./fields/UrlField";
import { UrlType } from "./fields/enum/UrlType";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldMinDateOrNull } from "./fields/validators/FieldMinDateOrNull";
import { FieldNumberMinMax } from "./fields/validators/FieldPercentageMinMax";
import { FieldValidConsultantEndDate } from "./fields/validators/FieldValidEmployeeEndDate";

export class ConsultantDisplayable extends DisplayableBase<Consultant> {
  @ValidateNested()
  name: DropdownField<User, UserDisplayable>;
  @ValidateNested()
  urlName: UrlField;
  @ValidateNested()
  group: DropdownField<ConsultantGroup, ConsultantGroupDisplayable>;
  corporation: TableField;
  @ValidateNested()
  employmentStart: DateField;
  @ValidateNested()
  @FieldMinDateOrNull("employmentStart")
  @FieldValidConsultantEndDate(() => AssignmentData, "consultantId")
  @FieldValidConsultantEndDate(() => AbsenceData, "consultantId")
  employmentEnd: NullableDateField;
  @ValidateNested()
  keyCompetencies: TableField;
  @ValidateNested()
  competencies: TableField;
  @ValidateNested()
  services: TableField;
  @ValidateNested()
  certificates: TableField;
  @ValidateNested()
  @FieldNumberMinMax()
  coverage: PercentageField;
  @ValidateNested()
  aboutMe: TextAreaField;
  @ValidateNested()
  ambition: TextAreaField;
  files: FilesField;
  urlField: FilesUrlField;

  constructor(consultant: Consultant) {
    super();
    const consultantData = container.resolve(ConsultantData);
    const corporationData = container.resolve(CorporationData);
    const userData = container.resolve(UserData);
    const consultantGroupData = container.resolve(ConsultantGroupData);
    const leaderData = container.resolve(LeaderData);
    const nisConsultantData = container.resolve(NisConsultantData);
    const { headers } = consultantData;
    const name = `${consultant.firstname} ${consultant.lastname}`;
    const fileData = container.resolve(FileData);

    /* Used to filter out users that already has a consultant,
     * unless we're editing a consultant,
     * in which case the corresponding user is shown
     * Also filters out leaders. */
    const userFilter = (selected: DropdownOption<string>) => {
      return (
        !nisConsultantData.some("userId", selected.id) &&
        !leaderData.some("userId", selected.id) &&
        !consultantData.rows.some(
          (c) => c.userId == selected.id && c.userId != consultant.userId
        )
      );
    };

    this.name = new DropdownField(
      headers.name,
      name,
      userData,
      new DropdownOption(consultant.userId, name),
      {
        optionsFilter: userFilter,
      }
    );
    this.name.editImpossible = true;
    this.urlName = new UrlField(
      headers.name,
      name,
      `/${process.env.VUE_APP_PROFILE_ROUTE}/${consultant.userId}`
    );
    this.group = new DropdownField(
      headers.group,
      consultant.consultantGroupName,
      consultantGroupData,
      new DropdownOption(
        consultant.consultantGroupId,
        consultant.consultantGroupName
      ),
      {
        selectionFilter: new DropdownFieldSelectionFilter(
          corporationData,
          "corporationId",
          "corporationName"
        ),
      }
    );
    this.corporation = new TableField(
      headers.corporation,
      consultant.corporationName
    );
    this.employmentStart = new DateField(
      headers.employmentStart,
      consultant.startDate
    );
    this.employmentEnd = new NullableDateField(
      headers.employmentEnd,
      consultant.endDate
    );

    this.keyCompetencies = new TableField(
      headers.keyCompetencies,
      createCompetencyList(consultant.consultantId, true)
    );
    this.competencies = new TableField(
      headers.competencies,
      createCompetencyList(consultant.consultantId, false)
    );
    this.services = new TableField(
      headers.services,
      createServiceList(consultant.consultantId)
    );
    this.certificates = new TableField(
      headers.certificates,
      createCertificateListWithDates(consultant.consultantId)
    );
    this.coverage = new PercentageField(headers.coverage, consultant.coverage, {
      minNumericValue: 10,
    });
    this.aboutMe = new TextAreaField(headers.aboutMe, consultant.aboutMe, {
      hideInTable: true,
      maxLength: 400,
      cols: 30,
      wrap: "soft",
    });
    this.ambition = new TextAreaField(headers.ambition, consultant.ambition, {
      hideInTable: true,
      maxLength: 400,
      cols: 30,
      wrap: "soft",
    });
    this.files = new FilesField(
      headers.files,
      FileFolder.Profile,
      consultant.userId,
      fileData
    );
    this.urlField = new FilesUrlField(
      headers.urlField,
      consultant.consultantId,
      UrlType.Consultant
    );
  }

  toModel(consultant: Consultant): void {
    consultant.startDate = this.employmentStart.modelValue();
    consultant.endDate = this.employmentEnd.modelValue();
    consultant.consultantGroupId = this.group.selectedOption.id;
    consultant.userId = this.name.selectedOption.id;
    consultant.coverage = this.coverage.modelValue();
    consultant.aboutMe = this.aboutMe.modelValue();
    consultant.ambition = this.ambition.modelValue();
  }
}
