import { CorporationData } from "@/store/data/CorporationData";
import { CurrencyData } from "@/store/data/CurrencyData";
import { CustomerData } from "@/store/data/CustomerData";
import { InvoiceData } from "@/store/data/InvoiceData";
import { NisAssignmentData } from "@/store/data/NisAssignmentData";
import { NisConsultantData } from "@/store/data/NisConsultantData";
import { NisConsultantGroupData } from "@/store/data/NisConsultantGroupData";
import { NisServiceData } from "@/store/data/NisServiceData";
import { TaskmasterData } from "@/store/data/TaskmasterData";
import { dateKey } from "@/types/DateKey";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import Invoice from "../Invoice";
import { NisAssignment } from "../NisAssignment";
import { NisConsultant } from "../NisConsultant";
import NisService from "../NisService";
import { Taskmaster } from "../Taskmaster";
import { DisplayableBase } from "./DisplayableBase";
import { InvoiceDisplayable } from "./InvoiceDisplayable";
import { NisConsultantDisplayable } from "./NisConsultantDisplayable";
import { NisServiceDisplayable } from "./NisServiceDisplayable";
import { TaskmasterDisplayable } from "./TaskmasterDisplayable";
import { DateField } from "./fields/DateField";
import {
  DropdownField,
  DropdownFieldSelectionFilter,
} from "./fields/DropdownField";
import { OneToManyField } from "./fields/OneToManyField";
import { LabelField, TableField } from "./fields/TableField";
import { TextAreaField } from "./fields/TextAreaField";
import { TextField } from "./fields/TextField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldDateGreaterThan } from "./fields/validators/FieldDateGreaterThan";
import { FieldDisabledOrNotHidden } from "./fields/validators/FieldDisabledOrNotHidden";
import { FieldNisConsultantEmployed } from "./fields/validators/FieldEmployeeEmployed";

export class NisAssignmentDisplayable extends DisplayableBase<NisAssignment> {
  @ValidateNested()
  name: TextField;
  @ValidateNested()
  nisConsultant: DropdownField<NisConsultant, NisConsultantDisplayable>;
  @ValidateNested()
  @FieldNisConsultantEmployed("nisConsultant")
  startDate: DateField;
  @ValidateNested()
  @FieldDateGreaterThan("startDate")
  @FieldNisConsultantEmployed("nisConsultant")
  endDate: DateField;
  @ValidateNested()
  taskmaster: DropdownField<Taskmaster, TaskmasterDisplayable>;
  @ValidateNested()
  @FieldDisabledOrNotHidden()
  nisService: DropdownField<NisService, NisServiceDisplayable>;
  @ValidateNested()
  customer: TableField;
  description: TextAreaField;
  @ValidateNested()
  totalInvoiced: LabelField;
  invoices: OneToManyField<
    NisAssignment,
    Invoice,
    NisAssignmentDisplayable,
    InvoiceDisplayable
  >;

  constructor(nisAssignment: NisAssignment) {
    super();
    const nisAssignmentData = container.resolve(NisAssignmentData);
    const taskmasterData = container.resolve(TaskmasterData);
    const customerData = container.resolve(CustomerData);
    const nisConsultantData = container.resolve(NisConsultantData);
    const nisConsultantGroupData = container.resolve(NisConsultantGroupData);
    const invoiceData = container.resolve(InvoiceData);
    const corporationData = container.resolve(CorporationData);
    const nisServiceData = container.resolve(NisServiceData);
    const currencyData = container.resolve(CurrencyData);

    const nisConsultantName = `${nisAssignment.firstname} ${nisAssignment.lastname}`;
    const { headers } = nisAssignmentData;

    this.name = new TextField(headers.name, nisAssignment.name, {
      maxLength: 50,
      placeholder: "Brief summary of assignment",
    });
    this.customer = new TableField(
      headers.customer,
      nisAssignment.customerName
    );
    this.description = new TextAreaField(
      headers.description,
      nisAssignment.description,
      {
        maxLength: 700,
        rows: 2,
        placeholder: "Purpose, objective, tasks, ...",
      }
    );
    this.startDate = new DateField(headers.startDate, nisAssignment.startDate);
    this.endDate = new DateField(headers.endDate, nisAssignment.endDate);
    this.taskmaster = new DropdownField(
      headers.taskmaster,
      nisAssignment.taskmasterName,
      taskmasterData,
      new DropdownOption(
        nisAssignment.taskmasterId,
        nisAssignment.taskmasterName
      ),
      {
        selectionFilter: new DropdownFieldSelectionFilter(
          customerData,
          "customerId",
          "customerName"
        ),
      }
    );

    this.nisConsultant = new DropdownField(
      headers.nisConsultant,
      nisConsultantName,
      nisConsultantData,
      new DropdownOption(nisAssignment.nisConsultantId, nisConsultantName),
      {
        selectionFilter: new DropdownFieldSelectionFilter(
          nisConsultantGroupData,
          "nisConsultantGroupId",
          "nisConsultantGroupName",
          new DropdownFieldSelectionFilter(
            corporationData,
            "corporationId",
            "corporationName"
          )
        ),
      }
    );

    this.nisService = new DropdownField(
      headers.nisService,
      nisAssignment.nisServiceName,
      nisServiceData,
      new DropdownOption(
        nisAssignment.nisServiceId,
        nisAssignment.nisServiceName
      )
    );

    this.totalInvoiced = new LabelField(headers.totalInvoiced, () => {
      const invoices = invoiceData.findMany(
        "nisAssignmentId",
        nisAssignment.nisAssignmentId
      );
      if (invoices.length === 0) return "0";
      if (!invoices.every((i) => i.currencyId === invoices[0].currencyId))
        return "N/A (several currencies)";
      const commonCurrency = currencyData.findById(invoices[0].currencyId);
      const sum = invoices.reduce((s, c) => s + c.amount, 0);
      return commonCurrency.isPrefix
        ? `${commonCurrency.currencySymbol}${sum}`
        : `${sum} ${commonCurrency.currencySymbol}`;
    });

    this.invoices = new OneToManyField(
      headers.invoices,
      nisAssignment.nisAssignmentId,
      nisAssignment.name,
      "nisAssignmentId",
      "nisAssignmentName",
      "nisAssignment",
      invoiceData,
      Invoice,
      InvoiceDisplayable,
      (i) => `${i.amount} ${i.currencyCode} ${i.date}`,
      (i) => i.description,
      "date",
      {
        Date: "date",
        Amount: (i) => i.amount.toLocaleString(),
        Currency: "currencyCode",
      }
    );
  }

  toModel(nisAssignment: NisAssignment): void {
    nisAssignment.name = this.name.modelValue();
    nisAssignment.startDate = dateKey(this.startDate.modelValue());
    nisAssignment.endDate = dateKey(this.endDate.modelValue());
    nisAssignment.taskmasterId = this.taskmaster.selectedOption.id;
    nisAssignment.nisConsultantId = this.nisConsultant.selectedOption.id;
    nisAssignment.nisServiceId = this.nisService.selectedOption.id;
    nisAssignment.description = this.description.modelValue() ?? "";
  }
}
