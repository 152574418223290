import { IClosedDated } from "@/interfaces/Dated";
import { DateKey } from "@/types/DateKey";
import { ModelBase } from "./ModelBase";
import { AssignmentDisplayable } from "./displayable/AssignmentDisplayable";
import { ContractPhase } from "./enum/ContractPhase";

export class Assignment
  extends ModelBase<AssignmentDisplayable>
  implements IClosedDated
{
  assignmentId: number;
  name: string;
  phase: ContractPhase;
  coverage: number;
  startDate: DateKey;
  endDate: DateKey;
  consultantId: number;
  extension: number;

  taskmasterName: string;
  taskmasterId: number;

  firstname: string;
  lastname: string;

  serviceName: string;
  serviceId: number;

  customerName: string;

  corporationName: string;

  hourlyRate: number;

  currencyId: number;
  currencyCode: string;

  description: string;
  workTasks: string;

  constructor() {
    super();

    this.assignmentId = 0;
    this.name = "";
    this.phase = ContractPhase.PROBABLE_ASSIGNMENT;
    this.coverage = 100;
    this.startDate = "" as DateKey;
    this.endDate = "" as DateKey;
    this.taskmasterId = 0;
    this.consultantId = 0;
    this.extension = 0;

    this.taskmasterName = "";
    this.firstname = "";
    this.lastname = "";

    this.serviceId = 0;
    this.serviceName = "";

    this.customerName = "";
    this.corporationName = "";

    this.hourlyRate = 0;

    this.currencyId = 0;
    this.currencyCode = "";

    this.description = "";
    this.workTasks = "";
  }

  realize(partialModel: Assignment): Assignment {
    const newModel = new Assignment();

    newModel.assignmentId = partialModel.assignmentId;
    newModel.name = partialModel.name;
    newModel.phase = partialModel.phase;
    newModel.coverage = partialModel.coverage;
    newModel.startDate = partialModel.startDate;
    newModel.endDate = partialModel.endDate;
    newModel.taskmasterId = partialModel.taskmasterId;
    newModel.consultantId = partialModel.consultantId;
    newModel.extension = partialModel.extension;

    newModel.taskmasterName = partialModel.taskmasterName;
    newModel.firstname = partialModel.firstname;
    newModel.lastname = partialModel.lastname;

    newModel.serviceId = partialModel.serviceId;
    newModel.serviceName = partialModel.serviceName;

    newModel.customerName = partialModel.customerName;
    newModel.corporationName = partialModel.corporationName;

    newModel.hourlyRate = partialModel.hourlyRate;

    newModel.currencyId = partialModel.currencyId;
    newModel.currencyCode = partialModel.currencyCode;

    newModel.description = partialModel.description;
    newModel.workTasks = partialModel.workTasks;

    return newModel;
  }

  getId(): number {
    return this.assignmentId;
  }

  getName(): string {
    return this.name;
  }

  getStartDate(): Date {
    return new Date(this.startDate);
  }

  getEndDate(): Date {
    return new Date(this.endDate);
  }

  getDisplayable(): AssignmentDisplayable {
    return new AssignmentDisplayable(this);
  }

  static csvHeaders(): string {
    return (
      `id;assignment;customer;contact;phase;coverage;hourlyRate;` +
      `startDate;endDate;firstname;lastname;service;corporation;` +
      `description;workTasks`
    );
  }

  toCsvString(): string {
    return (
      `${this.assignmentId};${this.name};${this.customerName};` +
      `${this.taskmasterName};${this.phase ? "yes" : "probable"};` +
      `${this.coverage};${this.hourlyRate};${this.startDate};` +
      `${this.endDate};${this.firstname};${this.lastname};` +
      `${this.serviceName};${this.corporationName};` +
      `${this.description};${this.workTasks}`
    );
  }
}
