import { TableHeaders } from "@/interfaces/TableHeaders";
import { CustomerDisplayable } from "@/models/displayable/CustomerDisplayable";

export class CustomerHeaders implements TableHeaders<CustomerDisplayable> {
  public readonly name = "Customer Name";
  public readonly leaderName = "Responsible";
  public readonly priorityName = "Priority";
  public readonly locationName = "Location";
  public readonly industryName = "Industry";
  public readonly corporationCustomerName = "Corporation[s]";
  public readonly potential = "Potential";
  public readonly frameworkAgreement = "Framework Agreement";
}
