import FileFolder from "@/components/enum/FileFolder";
import { Certificate } from "@/models/Certificate";
import { Competency } from "@/models/Competency";
import { Consultant } from "@/models/Consultant";
import { ConsultantGroup } from "@/models/ConsultantGroup";
import { Corporation } from "@/models/Corporation";
import { CertificateDisplayable } from "@/models/displayable/CertificateDisplayable";
import { ConsultantDisplayable } from "@/models/displayable/ConsultantDisplayable";
import { ConsultantGroupDisplayable } from "@/models/displayable/ConsultantGroupDisplayable";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { EditCompetenciesExtension } from "../tableDataOptionsExtensions/EditCompetenciesExtension";
import { FilesExtension } from "../tableDataOptionsExtensions/FilesExtension";
import { ConsultantFilter } from "../tableDataOptionsFilters/ConsultantFilter";
import { FilesFilter } from "../tableDataOptionsFilters/FilesFilter";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { FilterBuilder } from "../types/FilterBuilder";

export class ConsultantOptions extends TableDataOptions<
  Consultant,
  ConsultantDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;
  private consultantGroupDropdown: TableDataDropdown<
    ConsultantGroup,
    ConsultantGroupDisplayable
  >;
  private certificateDropdown: TableDataDropdown<
    Certificate,
    CertificateDisplayable
  >;

  override filterRows(
    rows: Consultant[],
    radioFilters: BaseTableFilterMode[],
    _yearFilter: number,
    selectedCompetencies?: Competency[] | undefined
  ): Consultant[] {
    const corporationFilter: number = this.corporationDropdown.selectedOption;
    const consultantGroupFilter: number =
      this.consultantGroupDropdown.selectedOption;
    const certificateFilter: number = this.certificateDropdown.selectedOption;
    const now = Date.now();
    const filters = new FilterBuilder<Consultant>();

    const [consultantFilter, fileFilter] = radioFilters;

    if (consultantFilter == BaseTableFilterMode.ACTIVE) {
      filters.add(TableDataOptions.isEmployeeCurrent(now));
    } else if (consultantFilter == BaseTableFilterMode.INACTIVE) {
      filters.add(TableDataOptions.isEmployeeNotCurrent(now));
    }

    if (consultantGroupFilter != -1) {
      filters.add(
        (consultant) => consultant.consultantGroupId == consultantGroupFilter
      );
    } else if (corporationFilter != -1) {
      const consultantGroupIds = store.state.consultantGroupData.findIds(
        "corporationId",
        corporationFilter
      );
      filters.add((consultant) =>
        consultantGroupIds.has(consultant.consultantGroupId)
      );
    }

    if (certificateFilter != -1) {
      const consultantIds = store.state.consultantCertificateData
        .findMany("certificateId", certificateFilter)
        .map((c) => c.consultantId);
      const set = new Set(consultantIds);
      filters.add((consultant) => set.has(consultant.consultantId));
    }

    if (fileFilter == BaseTableFilterMode.HAS_FILES) {
      filters.add((consultant) => this.filesExtension?.hasFiles(consultant));
    } else if (fileFilter == BaseTableFilterMode.NO_FILES) {
      filters.add((consultant) => !this.filesExtension?.hasFiles(consultant));
    }

    if (selectedCompetencies && selectedCompetencies.length > 0) {
      filters.add(this.getSelectedCompetenciesFilter(selectedCompetencies));
    }

    return filters.filter(rows);
  }

  public constructor() {
    const { corporationData, consultantGroupData, certificateData } =
      store.state;

    super({
      hiddenFields: ["name", "aboutMe", "ambition"],
      radioFilters: [new ConsultantFilter(), new FilesFilter()],
      sorting: {
        "First Name": {
          func: TableDataOptions.compareFirstNames,
          column: "urlName",
        },
        "Last Name": TableDataOptions.compareLastNames,
        "Employment Start": {
          func: TableDataOptions.compareStart,
          column: "employmentStart",
        },
        "Employment End": {
          func: TableDataOptions.compareNullableEnd,
          column: "employmentEnd",
        },
      },
      filesExtension: new FilesExtension(
        (consultant) => consultant.userId,
        FileFolder.Profile
      ),
      editCompetenciesExtension: new EditCompetenciesExtension(
        (consultant) => consultant.userId
      ),
      filterBoxKeys: [
        "urlName",
        "corporation",
        "group",
        "employmentStart",
        "competencies",
        "keyCompetencies",
        "certificates",
        "services",
      ],
    });

    this.corporationDropdown = new TableDataDropdown(corporationData);
    this.consultantGroupDropdown = new TableDataDropdown(
      consultantGroupData,
      undefined,
      this.getDropdownFilterFromRelatedDropdown(
        consultantGroupData,
        this.corporationDropdown,
        "corporationId"
      )
    );
    this.certificateDropdown = new TableDataDropdown(certificateData);

    this.dropdownFilters = [
      this.corporationDropdown,
      this.consultantGroupDropdown,
      this.certificateDropdown,
    ];
  }
}
