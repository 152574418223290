<template>
  <div class="control-panel" style="display: flex; align-items: start">
    <form @submit.prevent>
      <UnselectableLabel>
        Company
        <select id="company-selector" v-model="corporationIdModel">
          <option v-if="hasAll" name="Company" :value="-1">&lt;All&gt;</option>
          <option
            v-for="c in corporations"
            :key="c.corporationId"
            :value="c.corporationId"
          >
            {{ c.name }}
          </option>
        </select>
      </UnselectableLabel>
      <UnselectableLabel v-show="showLabel" v-if="!hideConsultantGroupFilter">
        Consultant Group
        <select id="consultant-group-selector" v-model="consultantGroupIdModel">
          <option name="ConsultantGroup" :value="-1">&lt;All&gt;</option>
          <option
            v-for="cg in consultantGroups"
            :key="cg.consultantGroupId"
            :value="cg.consultantGroupId"
          >
            {{ cg.name }}
          </option>
        </select>
      </UnselectableLabel>
      <UnselectableLabel v-show="showLabel" v-if="!hideCustomerFilter">
        Customer
        <select id="customer-selector" v-model="customerIdModel">
          <option name="Customer" :value="-1">&lt;All&gt;</option>
          <option
            v-for="c in customers"
            :key="c.customerId"
            :value="c.customerId"
          >
            {{ c.name }}
          </option>
        </select>
      </UnselectableLabel>
      <UnselectableLabel v-show="showLabel" v-if="!hideCurrencyFilter">
        Currency
        <select id="currency-selector" v-model="currencyIdModel">
          <option
            v-for="c in currencies"
            :key="c.currencyId"
            :value="c.currencyId"
          >
            {{
              c.currencyCode +
              (c === defaultCurrencyOfCorp() ? " (default)" : "")
            }}
          </option>
        </select>
      </UnselectableLabel>
      <slot></slot>
    </form>
  </div>
</template>

<script setup lang="ts">
import store from "@/store";
import { computed } from "vue";
import UnselectableLabel from "../UnselectableLabel.vue";

export interface Props {
  showLabel?: boolean;
  hasAll?: boolean;
  hideConsultantGroupFilter?: boolean;
  hideCurrencyFilter?: boolean;
  hideCustomerFilter?: boolean;
}
const {
  corporationData,
  consultantGroupData,
  consultantData,
  assignmentData,
  currencyData,
  taskmasterData,
  customerData,
  nisConsultantGroupData,
  nisConsultantData,
  nisAssignmentData,
  invoiceData,
} = store.state;

defineProps<Props>();

const corporationIdModel = defineModel<number>("corporationId", {
  default: -1,
  set: (id) => {
    if (id > 0) {
      const consultantGroupId = consultantGroupIdModel.value;
      if (consultantGroupId > 0) {
        const hasMatch = consultantGroups.value.some(
          (cg) =>
            cg.consultantGroupId == consultantGroupId && cg.corporationId == id
        );
        if (!hasMatch) {
          consultantGroupIdModel.value = -1;
        }
      }

      currencyIdModel.value = defaultCurrencyOfCorp(id).currencyId;
      customerIdModel.value = -1;
    }

    return id;
  },
});
const consultantGroupIdModel = defineModel<number>("consultantGroupId", {
  default: -1,
});
const currencyIdModel = defineModel<number>("currencyId", {
  default: -1,
});
const customerIdModel = defineModel<number>("customerId", {
  default: -1,
});

const corporations = computed(() => {
  const corporations = corporationData.rows;
  return corporations.sort((a, b) => a.getName().localeCompare(b.getName()));
});
const consultantGroups = computed(() => {
  const consultantGroups =
    corporationIdModel.value <= 0
      ? consultantGroupData.rows
      : consultantGroupData.findMany("corporationId", corporationIdModel.value);
  return consultantGroups.sort((a, b) =>
    a.getName().localeCompare(b.getName())
  );
});
const assignments = computed(() => {
  const consultants = consultantData.findWithValuesInSet(
    "consultantGroupId",
    new Set(consultantGroups.value.map((cg) => cg.consultantGroupId))
  );
  const assignments = assignmentData.findWithValuesInSet(
    "consultantId",
    new Set(consultants.map((c) => c.consultantId))
  );
  return assignments;
});
const currencies = computed(() => {
  const nisConsultantGroupIds = nisConsultantGroupData.findIds(
    "corporationId",
    corporationIdModel.value
  );
  const nisConsultantsIds = nisConsultantData.findWithValuesInSetIds(
    "nisConsultantGroupId",
    nisConsultantGroupIds
  );
  const nisAssignmentIds = nisAssignmentData.findWithValuesInSetIds(
    "nisConsultantId",
    nisConsultantsIds
  );
  const invoices = invoiceData.findWithValuesInSet(
    "nisAssignmentId",
    nisAssignmentIds
  );

  const currenciesOfCorp = new Set(
    [...assignments.value, ...invoices].map((o) => o.currencyId)
  );

  const currencies = currencyData.findWithValuesInSet(
    "currencyId",
    currenciesOfCorp
  );
  return currencies;
});
const defaultCurrencyOfCorp = (corpId?: number) =>
  currencyData.findById(
    corporationData.findById(corpId ?? corporationIdModel.value).currencyId
  );
const customers = computed(() => {
  const taskmasters = taskmasterData.findWithValuesInSet(
    "taskmasterId",
    new Set(assignments.value.map((a) => a.taskmasterId))
  );
  const customers = customerData.findWithValuesInSet(
    "customerId",
    new Set(taskmasters.map((a) => a.customerId))
  );
  return customers;
});
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

form {
  @include form;
  @include form-field;

  input,
  select {
    border-radius: 4px;
    border-color: $border-color;
    height: 1.8rem;

    &:invalid {
      outline: 2px solid $color-red;
    }
  }

  label {
    @include label;
  }
}
</style>
