import { Assignment } from "@/models/Assignment";
import { Corporation } from "@/models/Corporation";
import { Taskmaster } from "@/models/Taskmaster";
import { CorporationDisplayable } from "@/models/displayable/CorporationDisplayable";
import { TaskmasterDisplayable } from "@/models/displayable/TaskmasterDisplayable";
import store from "@/store";
import { TableDataOptions } from "../TableDataOptions";
import { BaseTableFilterMode } from "../enum/BaseTableFilterMode";
import { AssignmentFilter } from "../tableDataOptionsFilters/AssignmentFilter";
import { TableDataDropdown } from "../tableDataOptionsFilters/TableDataDropdown";
import { Filter, FilterBuilder } from "../types/FilterBuilder";

export class TaskmasterOptions extends TableDataOptions<
  Taskmaster,
  TaskmasterDisplayable
> {
  private corporationDropdown: TableDataDropdown<
    Corporation,
    CorporationDisplayable
  >;

  public constructor() {
    super({
      hiddenFields: ["firstname", "lastname"],
      radioFilters: [new AssignmentFilter()],
      sorting: {
        "Full Name": {
          func: TableDataOptions.compareFirstNames,
          column: "fullName",
        },
        Customer: { key: "customerName", column: "customer" },
      },
      filterBoxKeys: ["customer", "fullName"],
    });

    const { corporationData } = store.state;

    this.corporationDropdown = new TableDataDropdown(corporationData);

    this.dropdownFilters = [this.corporationDropdown];
  }

  override filterRows(
    rows: Taskmaster[],
    radioFilters: BaseTableFilterMode[]
  ): Taskmaster[] {
    const corporationFilter: number = this.corporationDropdown.selectedOption;
    const filters = new FilterBuilder<Taskmaster>();

    const {
      customerData,
      locationData,
      assignmentData,
      corporationCustomerData,
    } = store.state;

    if (corporationFilter != -1) {
      const locationIds = locationData.findIds(
        "corporationId",
        corporationFilter
      );

      const customerIds = customerData.findWithValuesInSetIds(
        "locationId",
        locationIds
      );

      corporationCustomerData
        .findMany("corporationId", corporationFilter)
        .forEach((c) => customerIds.add(c.customerId));

      filters.add((taskmaster) => customerIds.has(taskmaster.customerId));
    }

    const now = Date.now();

    let assignmentFilter: undefined | Filter<Assignment>;

    if (radioFilters[0] == BaseTableFilterMode.CURRENT_ASSIGNMENTS) {
      assignmentFilter = TableDataOptions.isPeriodCurrent(now);
    } else if (radioFilters[0] == BaseTableFilterMode.PREVIOUS_ASSIGNMENTS) {
      assignmentFilter = TableDataOptions.isPeriodNotCurrent(now);
    }

    if (!assignmentFilter) {
      return filters.filter(rows);
    }

    const taskmasterIds = assignmentData.rows
      .filter(assignmentFilter)
      .map((assignment) => assignment.taskmasterId);
    const set = new Set(taskmasterIds);
    filters.add((taskmaster) => set.has(taskmaster.taskmasterId));

    return filters.filter(rows);
  }
}
