/**
 * Constants that correspond to HTML input/select types.
 */
export enum FieldType {
  NONE,
  INPUT,
  TEXT_AREA,
  RADIO,
  DROP_DOWN,
  ENUM_DROP_DOWN,
  DATE,
  URL,
  NUMERIC,
  FILES,
  PERCENTAGE,
  SELECTION,
  URLFILES,
  MANY_TO_MANY,
  ONE_TO_MANY,
}
