import { IDisplayable } from "@/interfaces/Displayable";
import { NullableNumberField } from "../NullableNumberField";
import { NumberField } from "../NumberField";
import { PercentageField } from "../PercentageField";
import { VeaValidate } from "./VeaValidator";

export function FieldNumberMinMax<D extends IDisplayable>() {
  return VeaValidate<D, PercentageField | NumberField | NullableNumberField>({
    message(field) {
      const value = field.modelValue()!;
      if (value < field.min) return field.error.minValueError(field.min, value);
      else return field.error.maxValueError(field.max, value);
    },
    validate(field) {
      const value = field.modelValue();
      return value == null || (value >= field.min && value <= field.max);
    },
  });
}
