<template>
  <BaseModal
    :title="modalTitle"
    modal-icon="pencil-alt"
    :aborttitle="navigator.canBack() ? undefined : 'Cancel'"
    :abort="abort"
    :danger="false"
    :accepttitle="isEditing ? 'Save ' : 'Create'"
    :accept="submit"
    :error="hasError"
    :display-back-button="navigator.canBack()"
    @back="navigateBack(() => navigator.back())"
  >
    <form
      ref="form"
      :class="{
        'form-swipe-out-to-right': swipe == Swipe.OutToRight,
        'form-swipe-out-to-left': swipe == Swipe.OutToLeft,
        'form-swipe-in-from-right': swipe == Swipe.InFromRight,
        'form-swipe-in-from-left': swipe == Swipe.InFromLeft,
      }"
      @keyup.enter="onPressEnter"
      @submit.prevent
    >
      <template v-for="(field, fieldKey) in displayable">
        <div
          :id="`${String(fieldKey)}`"
          :key="generateKey(fieldKey)"
          class="mb-3"
          v-if="
            field.type !== FieldType.URL &&
            field.type !== FieldType.FILES &&
            !field.hideInForm &&
            field.enabled &&
            (navigator.current.data.fieldProvider?.isFieldAllowed(fieldKey) ??
              true)
          "
        >
          <div v-if="(field as Dropdown)?.editImpossible && isEditing">
            {{ field?.value }}
          </div>
          <label
            class="form-label col-auto"
            v-if="!(field as Dropdown).editImpossible || !isEditing"
            :for="'input' + field.type + (fieldKey as string)"
          >
            <strong>{{ field.header + (field.mandatory ? " *" : "") }}</strong>
            <!-- {{ field.unit ? ` (${field.unit})` : "" }} -->
          </label>
          <div
            class="field row g-1"
            v-if="!(field as Dropdown)?.editImpossible || !isEditing"
          >
            <div class="col-10" v-if="field.type == FieldType.INPUT">
              <DisplayableInput
                :field="field"
                :hasError="fieldHasErrors(fieldKey)"
                @input-updated="validateForm"
              />
            </div>
            <div
              class="col-10"
              v-if="field.type == FieldType.NONE && !field.hideInTable"
            >
              {{ field.value }}
            </div>
            <div class="col-10" v-if="field.type == FieldType.TEXT_AREA">
              <DisplayableTextAreaVue
                :field="(field as TextAreaField)"
                :has-error="fieldHasErrors(fieldKey)"
                @input-updated="validateForm"
              />
            </div>
            <DisplayableManyToMany
              v-if="
                isEditing &&
                field.type == FieldType.MANY_TO_MANY &&
                field instanceof ManyToManyField
              "
              :can-create-children="field.canCreateChildren"
              :parent-id="store.state.connectionRowId || -1"
              :parent-data="(navigator.current.data as TableData<any, any>)"
              :connection-data="field.connectionData"
              :child-data="field.childData"
              :parent-id-field="field.parentIdField"
              :connection-id-field="field.connectionIdField"
              :connection-parent-id-field="field.connectionParentIdField"
              :connection-parent-name-field="field.connectionParentNameField"
              :connection-child-id-field="field.connectionChildIdField"
              :connection-child-name-field="field.connectionChildNameField"
              :child-selection-filter="field.childSelectionFilter"
            />

            <p
              v-if="
                !isEditing &&
                (field.type == FieldType.MANY_TO_MANY ||
                  field.type == FieldType.ONE_TO_MANY) &&
                (field instanceof ManyToManyField ||
                  field instanceof OneToManyField)
              "
            >
              Assign {{ field.childData.title.toLocaleLowerCase() }} by editing
              the {{ navigator.current.data.titleSingular.toLowerCase() }} after
              creating it
            </p>
            <template v-if="field.type == FieldType.DROP_DOWN">
              <DisplayableDropdown
                :field="(field as DropdownFieldAny)"
                :label="field.header"
                :hasError="fieldHasErrors(fieldKey)"
                :canAdd="
                  container
                    .resolve(AuthorizationManager)
                    .editBaseTable.isAuthorized()
                "
                @input-updated="validateForm(), refilterDropdowns(fieldKey)"
                @button-clicked="add(castKey(fieldKey))"
                @add-filter-button-clicked="addFilter(castKey(fieldKey))"
                @add-secondary-filter-button="
                  addSecondaryFilter(castKey(fieldKey))
                "
              />
            </template>
            <div class="col-auto" v-if="field.type == FieldType.ENUM_DROP_DOWN">
              <DisplayableEnumDropdown
                :field="(field as EnumDropdownField<any>)"
                :label="field.header"
                :has-error="fieldHasErrors(fieldKey)"
                @input-updated="validateForm"
              />
            </div>
            <div class="col-auto" v-if="field.type == FieldType.RADIO">
              <DisplayableRadio
                :field="(field as ConditionalField)"
                :hasError="fieldHasErrors(fieldKey)"
                @input-updated="validateForm"
              />
            </div>
            <div class="col-auto" v-if="field.type == FieldType.DATE">
              <DisplayableDate
                class="form-control"
                :field="(field as DateField | NullableDateField)"
                :hasError="fieldHasErrors(fieldKey)"
                @input-updated="validateForm"
              />
            </div>
            <div class="col-4" v-if="field.type == FieldType.NUMERIC">
              <DisplayableNumeric
                class="form-control"
                :field="(field as NumberField | NullableNumberField)"
                :hasError="fieldHasErrors(fieldKey)"
                @input-updated="validateForm"
              />
            </div>
            <div class="col-4" v-if="field.type == FieldType.PERCENTAGE">
              <DisplayablePercentage
                class="form-control"
                :field="field as PercentageField"
                :hasError="fieldHasErrors(fieldKey)"
                @input-updated="validateForm"
              />
            </div>
            <div class="col-auto" v-if="field.type == FieldType.SELECTION">
              <DisplayableSelection
                :field="(field as SelectionField<unknown>)"
                :hasError="fieldHasErrors(fieldKey)"
                @input-updated="validateForm"
              />
            </div>
            <div
              v-if="
                isEditing &&
                field.type == FieldType.ONE_TO_MANY &&
                field instanceof OneToManyField
              "
            >
              <DisplayableOneToMany
                :field
                :canCreateChildren="false"
                :parentData="(navigator.current.data as TableData<any, any>)"
                :action="props.action"
                :on-edit-child="(id: number) => editOneToManyChild(castKey(fieldKey), id)"
                @click-add="addOneToManyChild(castKey(fieldKey))"
                @delete="refreshLabels"
              />
            </div>
          </div>
          <p class="error-message" v-if="hasError && fieldHasErrors(fieldKey)">
            {{ navigator.current.data.getFieldValidationErrors(fieldKey) }}
          </p>
        </div>
      </template>
      <div class="col-auto mb-3 file-input-group" v-if="canAddFiles">
        <label for="formFileInput" class="form-label col-auto">
          Files to upload
        </label>
        <div class="input-group">
          <input
            type="file"
            multiple
            accept=".pdf"
            class="form-control"
            ref="fileInput"
            id="formFileInput"
            @input="handleFiles()"
          />
          <button
            v-if="state.hasFilesToClear"
            type="button"
            aria-label="Clear"
            class="btn btn-outline-secondary"
            @click.stop="clearFiles()"
          >
            ×
          </button>
        </div>
        <small
          class="mt-1 tip"
          :class="{ emphasized: state.hasInvalidContent }"
        >
          Accepted File Types: PDF, DOCX
        </small>
      </div>
    </form>

    <p v-if="hasError" class="error-message form-errors">
      <fa-icon id="icon-basemodalform" icon="times" />
      There are form errors.
    </p>
  </BaseModal>
</template>

<script
  setup
  lang="ts"
  generic="M extends Model<D>, D extends Displayable<M> & IterableFields<D, IDisplayableField> & PropsOf<D, DropdownFieldAny>"
>
import { Displayable } from "@/interfaces/Displayable";
import { Dropdown } from "@/interfaces/Dropdown";
import { Model } from "@/interfaces/Model";
import { ConditionalField } from "@/models/displayable/fields/ConditionalField";
import { DateField } from "@/models/displayable/fields/DateField";
import { IDisplayableField } from "@/models/displayable/fields/DisplayableField";
import { DropdownField } from "@/models/displayable/fields/DropdownField";
import { EnumDropdownField } from "@/models/displayable/fields/EnumDropdownField";
import { ManyToManyField } from "@/models/displayable/fields/ManyToManyField";
import { NullableDateField } from "@/models/displayable/fields/NullableDateField";
import { NullableNumberField } from "@/models/displayable/fields/NullableNumberField";
import { NumberField } from "@/models/displayable/fields/NumberField";
import {
  IOneToManyField,
  OneToManyField,
} from "@/models/displayable/fields/OneToManyField";
import { PercentageField } from "@/models/displayable/fields/PercentageField";
import { SelectionField } from "@/models/displayable/fields/SelectionField";
import { LabelField } from "@/models/displayable/fields/TableField";
import { TextAreaField } from "@/models/displayable/fields/TextAreaField";
import { FieldType } from "@/models/displayable/fields/enum/FieldType";
import { DropdownOption } from "@/models/displayable/fields/util/DropdownOption";
import store from "@/store";
import { AuthorizationManager } from "@/store/AuthorizationManager";
import FileData from "@/store/data/FileData";
import { TableData } from "@/store/data/TableData";
import VALID_FILE_TYPES from "@/store/data/constant/ValidFileTypes";
import { Action } from "@/store/data/enum/Action";
import { DropdownFieldAny } from "@/types/DropdownFieldAny";
import { FormEditingContext } from "@/types/FormEditingContext";
import { DropdownSource } from "@/types/FormEditingContextParentKey";
import { FormEditingNavigator } from "@/types/FormEditingNavigator";
import { IterableFields } from "@/types/IterableFields";
import { KeyTo } from "@/types/KeyTo";
import { ModalToggle } from "@/types/ModalToggle";
import { PropsOf } from "@/types/PropsOf";
import { castKey, getAllKeys, getProp, getValues } from "@/types/getProp";
import { container } from "tsyringe";
import { computed, reactive, ref } from "vue";
import BaseModal from "./BaseModal.vue";
import DisplayableDate from "./displayable/DisplayableDate.vue";
import DisplayableDropdown from "./displayable/DisplayableDropdown.vue";
import DisplayableEnumDropdown from "./displayable/DisplayableEnumDropdown.vue";
import DisplayableInput from "./displayable/DisplayableInput.vue";
import DisplayableManyToMany from "./displayable/DisplayableManyToMany.vue";
import DisplayableNumeric from "./displayable/DisplayableNumeric.vue";
import DisplayableOneToMany from "./displayable/DisplayableOneToMany.vue";
import DisplayablePercentage from "./displayable/DisplayablePercentage.vue";
import DisplayableRadio from "./displayable/DisplayableRadio.vue";
import DisplayableSelection from "./displayable/DisplayableSelection.vue";
import DisplayableTextAreaVue from "./displayable/DisplayableTextArea.vue";

const props = defineProps<{
  data: TableData<M, D>;
  action: Action;
  submitEvent: () => void;
  updateDropdownEvent?: (dropdown: DropdownFieldAny) => Promise<void>;
  nested?: boolean;
  extraValues?: Partial<M>;
  toggle?: ModalToggle;
}>();

const navigator = reactive(
  new FormEditingNavigator(
    new FormEditingContext(props.data, props.action, props.extraValues)
  )
);

enum Swipe {
  None,
  InFromLeft,
  OutToLeft,
  InFromRight,
  OutToRight,
}

const isEditing = computed(() => navigator.current.action == Action.Edit);

const swipe = ref(Swipe.None);

const hasError = computed(() => {
  const { data } = navigator.current;
  return data.hasValidationErrors() && data.hasModifyingFailed();
});

function generateKey(fieldKey: string | number | symbol): string {
  if (typeof fieldKey == "symbol") {
    fieldKey = String(fieldKey);
  }
  return `${navigator.current.data.titleSingular
    .replace(/[^a-z0-9]/g, "-")
    .toLocaleLowerCase()}-${fieldKey}`;
}

async function submit() {
  if (navigator.canBack()) {
    const success = await navigator.current.accept();
    if (success) {
      navigateBack(() => navigator.back()).then(() => refilterDropdowns());
    }
    return;
  }

  await props.data.saveChanges(displayable.value);

  await props.data.finished;

  await props.submitEvent();

  if (!props.data.hasValidationErrors()) {
    props.toggle?.hide();
  }
}

function abort() {
  navigator.cancel();
  props.toggle?.hide();
}

function refreshLabels() {
  for (const field of getValues<D, IDisplayableField>(displayable.value)) {
    if (field.type == FieldType.NONE && field instanceof LabelField) {
      field.refresh();
    }
  }
}

function editOneToManyChild(key: KeyTo<D, IOneToManyField>, childId: number) {
  navigateForward(() => navigator.addOrEditOneToMany(key, childId));
}

function addOneToManyChild(key: KeyTo<D, IOneToManyField>) {
  navigateForward(() => navigator.addOrEditOneToMany(key));
}

function add(key: KeyTo<D, DropdownFieldAny>) {
  navigateForward(() => navigator.addOption(key, DropdownSource.Main));
}

function addFilter(key: KeyTo<D, DropdownFieldAny>) {
  navigateForward(() => navigator.addOption(key, DropdownSource.FirstFilter));
}

function addSecondaryFilter(key: KeyTo<D, DropdownFieldAny>) {
  navigateForward(() => navigator.addOption(key, DropdownSource.SecondFilter));
}

async function onPressEnter() {
  if (document?.activeElement?.tagName != "TEXTAREA") {
    submit();
  }
}

const form = ref<HTMLFormElement | null>(null);

const modalTitle = computed(
  () => `${navigator.current.action} ${navigator.current.data.titleSingular}`
);

const fileInput = ref<HTMLInputElement | null>(null);

function handleFiles() {
  if (!navigator.current.data.hasFiles || !fileInput.value?.files) {
    return;
  }
  const hasValidFiles = Array.from(fileInput.value.files).every((f) =>
    VALID_FILE_TYPES.includes(f.type)
  );
  if (!hasValidFiles) {
    clearFiles();
    state.hasInvalidContent = true;
    setTimeout(() => {
      state.hasInvalidContent = false;
    }, 2000);
    return;
  }
  state.hasFilesToClear = true;
  FileData.filesToUpload = fileInput.value.files;
}

function clearFiles() {
  if (fileInput.value) {
    FileData.clear();
    state.hasFilesToClear = false;
    fileInput.value.value = "";
    fileInput.value.files = null;
  }
}

async function navigateForward(newForm: () => void) {
  await swipeWithDelay(Swipe.OutToLeft).then(newForm).then(scrollToTop);
  swipeWithDelay(Swipe.InFromRight);
}

async function navigateBack(newForm: () => void) {
  const { parent } = navigator.current;
  await swipeWithDelay(Swipe.OutToRight).then(newForm);
  if (parent) {
    scrollToField(String(parent.fieldKey), parent.dropdownSource);
  } else {
    scrollToTop();
  }
  refreshLabels();
  swipeWithDelay(Swipe.InFromLeft);
}

function scrollToField(fieldKey: string, dropdownSource: DropdownSource) {
  document.getElementById(String(fieldKey))?.scrollIntoView();
  const field = getProp(
    displayable.value,
    fieldKey as KeyTo<D, IDisplayableField>
  );
  if (field.type != FieldType.DROP_DOWN) {
    return;
  }
  const suffixes: Record<DropdownSource, string> = {
    [DropdownSource.Main]: "select",
    [DropdownSource.FirstFilter]: "filter",
    [DropdownSource.SecondFilter]: "secondary-filter",
  };
  const suffix = suffixes[dropdownSource];
  const id = `${field.header.toLowerCase().replace(" ", "")}-${suffix}`;
  document.getElementById(id)?.focus();
}

async function swipeWithDelay(direction: Swipe) {
  swipe.value = direction;
  await new Promise((resolve) => setTimeout(resolve, 300));
}

function scrollToTop() {
  form.value?.scrollIntoView();
}

const state = reactive({
  hasFilesToClear: false,
  hasInvalidContent: false,
  addDropdownFilterData: false,
});

function fieldHasErrors(fieldKey: keyof D): boolean {
  if (!navigator.current.data.hasValidationErrors()) return false;
  if (!navigator.current.data.hasModifyingFailed()) return false;
  return navigator.current.data.hasFieldValidationErrors(fieldKey);
}

function refilterDropdowns(triggeringField?: keyof D) {
  for (const key of getAllKeys(displayable.value)) {
    if (key === triggeringField) {
      continue;
    }
    const field = getProp(displayable.value, key);
    if (field instanceof DropdownField == false) {
      continue;
    }
    field.refilter();
    if (field.options.some(({ id }) => id == field.selectedOption.id)) {
      continue;
    }
    field.selectedOption = field.nullable
      ? new DropdownOption(0, "<None>")
      : new DropdownOption(0, "");
  }
  validateForm();
}

async function validateForm() {
  await navigator.current.data.validateForm(displayable.value);
}

const displayable = computed(() => {
  // This cast is done to trick v-for to interpret all fields in the current displayable as `IDisplayableField`.
  return navigator.current.displayable as D;
});

const canAddFiles = computed(
  () =>
    !navigator.canBack() && props.data.hasFiles && props.action === Action.Edit
);
</script>

<style scoped lang="scss">
@import "@/styles/global.scss";

.modal-dialog {
  @include modal-dialog($width: max-content);
  min-width: 350px;

  .modal-content > * {
    padding: 1rem 1.5rem;
  }
}

.file-input-group {
  small.tip {
    display: block;
    text-align: center;
    transition: all 300ms;

    &.emphasized {
      font-size: 18px;
      transition: all 300ms;
      color: var(--danger-red);
      animation: notice-me 200ms 3;
    }

    @keyframes notice-me {
      0% {
        transform: translateX(0);
      }

      25% {
        transform: translateX(-5px);
      }

      50% {
        transform: translateX(0);
      }

      75% {
        transform: translateX(5px);
      }
    }
  }
}

.form-errors {
  text-align: center;
}

.error-message {
  @include error-message();

  * {
    vertical-align: middle;
  }

  #icon-basemodalform {
    width: 30px;
    height: 30px;
    color: $color-red;
  }
}

.mb-3 {
  text-align: start;
}

.form-swipe-in-from-left,
.form-swipe-in-from-right,
.form-swipe-out-to-left,
.form-swipe-out-to-right {
  animation-duration: 0.5s;
  position: relative;
}

.form-swipe-out-to-right {
  animation-name: swipe-out-to-right;
}

.form-swipe-out-to-left {
  animation-name: swipe-out-to-left;
}

.form-swipe-in-from-right {
  animation-name: swipe-in-from-right;
}

.form-swipe-in-from-left {
  animation-name: swipe-in-from-left;
}

@keyframes swipe-out-to-right {
  from {
    left: 0;
  }

  to {
    left: 200%;
  }
}

@keyframes swipe-in-from-right {
  from {
    left: 200%;
  }

  to {
    left: 0px;
  }
}

@keyframes swipe-in-from-left {
  from {
    left: -200%;
  }

  to {
    left: 0px;
  }
}

@keyframes swipe-out-to-left {
  from {
    left: 0px;
  }

  to {
    left: -200%;
  }
}
</style>
