import { TableHeaders } from "@/interfaces/TableHeaders";
import { RecruitmentDisplayable } from "@/models/displayable/RecruitmentDisplayable";

export class RecruitmentHeaders
  implements TableHeaders<RecruitmentDisplayable>
{
  public readonly consultantGroup = "Consultant Group";
  public readonly period = "Period";
  public readonly budget = "Budget";
  public readonly prediction = "Prediction";
  public readonly outcome = "Outcome";
  public readonly comment = "Comment";
  public readonly hasComment = ""; // deliberately empty column name
}
