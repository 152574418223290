<template>
  <h1>{{ title }}</h1>
  <p
    v-if="clarification"
    id="clarification-link"
    class="clarification-link"
    v-html="
      `${clarification}${
        hideFutureMonthsClarification
          ? ''
          : '<br />Lighter bars indicate future months.'
      }`
    "
  ></p>

  <TheReportControlPanel
    v-model:corporation-id="state.corporationId"
    v-model:consultant-group-id="state.consultantGroupId"
    v-model:currency-id="state.currencyId"
    v-model:customer-id="state.customerId"
    v-bind:show-label="true"
    v-on:update:corporation-id="recalculateAndRefreshChart"
    v-on:update:consultant-group-id="recalculateAndRefreshChart"
    v-on:update:currency-id="recalculateAndRefreshChart"
    v-on:update:customer-id="recalculateAndRefreshChart"
    :hasAll="props.canShowAllCorporations"
    :hideConsultantGroupFilter="hideConsultantGroupFilter"
    :hideCurrencyFilter="hideCurrencyFilter"
    :hideCustomerFilter="hideCustomerFilter"
    @change="updateDirectly"
  >
    <div class="newRow">
      <UnselectableLabel
        for="bar-span-select"
        v-if="yearlyView != YearlyViewBehavior.None"
      >
        Bar Span
        <select
          id="use-yearview"
          v-model="state.barSpan"
          @change="recalculateAndRefreshChart"
        >
          <option :value="BarSpan.Month">Month</option>
          <option :value="BarSpan.Quarter">Quarter</option>
          <option :value="BarSpan.Year">Year</option>
        </select>
      </UnselectableLabel>
    </div>
    <div class="newRow">
      <TheReportPeriodPicker
        v-model:start="state.start"
        v-model:end="state.end"
        v-on:update:end="checkAndrefreshUpdateButton"
        v-on:update:start="checkAndrefreshUpdateButton"
      ></TheReportPeriodPicker>
    </div>
    <UnselectableLabel for="show-names" v-if="props.namesField">
      <input type="checkbox" id="show-names" v-model="state.showNames" />
      Show names
    </UnselectableLabel>

    <slot>
      <!-- Add extra options here  -->
    </slot>
    <GenericButton
      :displayText="'Update'"
      :disabled="!canUpdate"
      @buttonClicked="recalculateAndRefreshChart"
    >
    </GenericButton>
  </TheReportControlPanel>

  <TheColorMap
    v-if="Object.keys(tableSettings.columns).length > 1"
    :content="mappedColors"
    :startOpen="true"
  >
  </TheColorMap>

  <p class="diagram-header" v-if="hasAnyData(data)">
    {{
      (unitPrefix !== undefined ? unitPrefix : "") ||
      (unitSuffix !== undefined ? unitSuffix : "")
    }}
  </p>

  <div id="chart" :style="[hasAnyData(data) ? '' : 'display: none']">
    <div id="tag" class="tag"></div>
  </div>

  <h4 id="noDataMessage" v-if="!hasAnyData(data)">No data to display.</h4>

  <div id="tableDataContainer" v-if="hasAnyData(data)">
    <h3>Data used in table:</h3>
    <table id="tableData">
      <thead>
        <tr>
          <th class="align-left">Year</th>
          <th v-if="showMonthColumn" class="align-left">Month</th>
          <th v-if="showQuarterColumn" class="align-left">Quarter</th>
          <th v-for="(_, header, i) in tableSettings.columns" :key="i">
            {{ header }}
          </th>
          <th v-if="tableSettings.showMonthlyTotal">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(x, i) in data" :key="i">
          <td>{{ x.month.getFullYear() }}</td>
          <td v-if="showMonthColumn" class="align-left">
            {{ x.month.toLocaleDateString(LOCALE_EN, { month: "long" }) }}
          </td>
          <td v-if="showQuarterColumn" class="align-left">
            {{ Quarter[quarterFromMonth(x.month.getMonth() as Month)] }}
          </td>
          <td v-for="(val, i) of tableSettings.columns" :key="i">
            {{ props.tableSettings.appendUnit ? unitPrefix : "" }}
            {{ formatCell(getCellValue(x, val)) }}
            {{ props.tableSettings.appendUnit ? unitSuffix : "" }}
          </td>
          <td v-if="tableSettings.showMonthlyTotal">
            {{ props.tableSettings.appendUnit ? unitPrefix : "" }}
            {{ formatCell(getRowTotal(x)) }}
            {{ props.tableSettings.appendUnit ? unitSuffix : "" }}
          </td>
          <template
            v-if="
              state.showNames &&
              props.namesField &&
              getProp(x, props.namesField)
            "
          >
            <td
              v-for="name in getProp(x, props.namesField)"
              :key="name"
              class="name-row"
              style="text-align: left"
            >
              {{ name }}
            </td>
          </template>
        </tr>
        <tr class="total-row" v-if="tableSettings.accumulatedTotal">
          <td>Total</td>
          <!-- intentionally empty for correct positioning -->
          <td v-if="showMonthColumn || showQuarterColumn"></td>
          <td v-for="(val, key) of totals" :key="key">
            {{ formatCell(val) }}
          </td>
          <td v-if="tableSettings.showMonthlyTotal">
            {{ formatCell(getAbsoluteTotal()) }}
          </td>
        </tr>
      </tbody>
    </table>

    <form @submit.prevent>
      <div class="btn-group">
        <button type="button" id="exportTableAsCsv" @click="handleCsvExport()">
          <fa-icon icon="share-square"></fa-icon>
          Export table as csv file
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts" generic="T extends { month: Date }, O">
import { LOCALE_EN, LOCALE_EN_US, LOCALE_SV_SE } from "@/Constant";
import TheColorMap from "@/components/TheColorMap.vue";
import UnselectableLabel from "@/components/UnselectableLabel.vue";
import GenericButton from "@/components/forms/GenericButton.vue";
import TheReportControlPanel from "@/components/reports/TheReportControlPanel.vue";
import TheReportPeriodPicker from "@/components/reports/TheReportPeriodPicker.vue";
import { Month } from "@/models/enum/Months";
import store from "@/store";
import { ColumnDef } from "@/types/ColumnDef";
import { CssColor } from "@/types/CssColor";
import {
  MonthKey,
  isMonthKeyString,
  monthFromKey,
  monthKey,
  monthKeyFromYearAndMonth,
  yearFromKey,
} from "@/types/DateKey";
import { MonthSpan, monthsInSpan } from "@/types/DateSpan";
import { KeyTo } from "@/types/KeyTo";
import { MappedColor } from "@/types/MappedColor";
import { PropsAs } from "@/types/PropsOf";
import {
  Quarter,
  quarterFromMonth,
  quartersInSpan,
  yearsInSpan,
} from "@/types/Quarter";
import {
  getAllEntries,
  getAllKeys,
  getAllProps,
  getProp,
  setProp,
} from "@/types/getProp";
import { DecimalUtils } from "@/util/DecimalUtils";
import { downloadBlob } from "@/util/DownloadHelper";
import { container } from "tsyringe";
import { Ref, computed, onMounted, reactive, ref } from "vue";
import { YearlyViewBehavior } from "./types/YearlyViewBehavior";
import generateStackedBarChart from "./util/generateStackedBarChart";

onMounted(() => {
  recalculateAndRefreshChart();
});

window.onresize = () => {
  if (hasAnyData(data.value)) refreshChart();
};

enum BarSpan {
  Month,
  Quarter,
  Year,
}

type TableSettings = {
  appendUnit: boolean;
  accumulatedTotal: boolean;
  showMonthlyTotal: boolean;
  fileName: string;
  columns: {
    [key: string]: ColumnDef<T>;
  };
  csvInfoRows?: PropsAs<O, string | number, { text: string; unit?: string }>;
};

type Props = {
  options: O;
  canShowAllCorporations: boolean;
  yearlyView: YearlyViewBehavior;
  tableSettings: TableSettings;
  valueFields: (KeyTo<T, number> & string)[];
  tooltipNameListFields?: PropsAs<T, string[], string>;
  tooltipNumberFields?: PropsAs<T, number, string>;
  colors: PropsAs<T, number, MappedColor>;
  calculateMonth: (
    month: MonthKey,
    corporationId: number,
    consultantGroupId?: number,
    currencyId?: number,
    customerId?: number,
    filters?: O
  ) => T;
  defaultPeriod?: MonthSpan;
  prefix?: string;
  suffix?: string;
  prefixFromOptions?: UnitFromOptionsFunc;
  suffixFromOptions?: UnitFromOptionsFunc;
  showUnitInBarTooltip?: boolean;
  snapshotData?: T[];
  namesField?: KeyTo<T, Set<string>>;
  decimals?: number;
  startDateMonthOffset?: number;
  endDateMonthOffset?: number;
  hideConsultantGroupFilter?: boolean;
  hideCurrencyFilter?: boolean;
  hideCustomerFilter?: boolean;
  title: string;
  clarification?: string;
  hideFutureMonthsClarification?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  startDateMonthOffset: -6,
  endDateMonthOffset: 5,
});

type UnitFromOptionsFunc = (
  corporationId: number,
  consultantGroupId?: number,
  currencyId?: number,
  filters?: O
) => string;

const canUpdate = defineModel<boolean>("canUpdate", { default: false });
const mappedColors = getAllProps(props.colors);

function updateDirectly() {
  if (canUpdate.value === true && isDateSet === false) {
    recalculateAndRefreshChart();
  }
  isDateSet = false;
}

const data = ref(new Array<T>()) as Ref<T[]>;
const { currencyData } = store.state;
const { corporationId, consultantGroupId } = store.state.settings;
const state = reactive({
  ...getDefaultPeriod(),
  corporationId,
  consultantGroupId: props.hideConsultantGroupFilter ? -1 : consultantGroupId,
  currencyId: props.hideCurrencyFilter
    ? -1
    : currencyData.getCompanyCurrencyOrDefault(corporationId).currencyId,
  customerId: -1,
  barSpan: BarSpan.Month,
  showNames: false,
});

function getDefaultPeriod(): MonthSpan {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const startMonth = currentMonth + props.startDateMonthOffset;
  const endMonth = currentMonth + props.endDateMonthOffset;
  return {
    start: monthKey(new Date(currentYear, startMonth)),
    end: monthKey(new Date(currentYear, endMonth)),
  };
}

function getCellValue(d: T, col: ColumnDef<T>) {
  if (typeof col == "function") {
    return col(d);
  }
  if (typeof col == "string") {
    return getProp(d, col);
  }
  if (col.func) {
    return col.func(d);
  }
  return getProp(d, col.key);
}

function getRowTotal(d: T): number {
  let i = 0;
  for (const field of props.valueFields) {
    i += getProp(d, field);
  }
  return i;
}

function getAbsoluteTotal() {
  let i = 0;
  for (const d of data.value) {
    i += getRowTotal(d);
  }
  return i;
}

function formatCell(value: number, useLocale = true) {
  const output = container
    .resolve(DecimalUtils)
    .limitDecimalCount(value, props.decimals ?? 0);
  return useLocale ? output.toLocaleString(LOCALE_SV_SE) : output;
}

const totals = computed(() => {
  const result = {} as { [key: string]: number };
  for (const c of getAllKeys(props.tableSettings.columns)) {
    let i = 0;
    const val = props.tableSettings.columns[c];
    for (const d of data.value) {
      i += getCellValue(d, val);
    }
    result[c] = i;
  }
  return result;
});

function refreshUpdateButton() {
  const { start, end } = state;
  if (!isMonthKeyString(start) || !isMonthKeyString(end)) {
    canUpdate.value = false;
    return;
  }
  if (start > end) {
    canUpdate.value = false;
    return;
  }
  canUpdate.value = true;
}

let isDateSet: boolean = false;

function checkAndrefreshUpdateButton() {
  refreshUpdateButton();
  isDateSet = true;
}

function hasAnyData(results: T[]) {
  return (
    results.length > 0 &&
    results.some((x) => props.valueFields.some((key) => x[key] != 0))
  );
}

function recalculateAndRefreshChart() {
  canUpdate.value = false;

  const results = calculateResults();

  if (!hasAnyData(results)) {
    data.value = [];
    return;
  }

  data.value = results;

  refreshChart();
}

function refreshChart() {
  generateStackedBarChart(
    data.value,
    props.valueFields,
    getColors(),
    getBarLabelFunc(),
    getDisplayInfoTagInnerHtml,
    "",
    ""
  );
}

function getBarLabelFunc() {
  switch (state.barSpan) {
    case BarSpan.Month:
      return getMonthBarLabel;
    case BarSpan.Quarter:
      return getQuarterBarLabel;
    case BarSpan.Year:
      return getYearBarLabel;
  }
}

function getMonthBarLabel(date: Date): string {
  return date.toLocaleDateString(LOCALE_EN_US, {
    month: "short",
    year: "numeric",
  });
}

function getQuarterBarLabel(date: Date): string {
  const quarter = quarterFromMonth(date.getMonth());
  return `${Quarter[quarter]} ${date.getFullYear()}`;
}

function getYearBarLabel(date: Date): string {
  return date.getFullYear().toString();
}

function getColors(): PropsAs<T, number, CssColor> {
  const colors = {} as PropsAs<T, number, CssColor>;
  for (const { key, value } of getAllEntries(props.colors)) {
    colors[key] = value.color;
  }
  return colors;
}

function calculateMonthlyResults(month: MonthKey) {
  return props.calculateMonth(
    month,
    state.corporationId,
    state.consultantGroupId,
    state.currencyId,
    state.customerId,
    props.options
  );
}

function calculateResults(): T[] {
  let months: MonthKey[];
  if (state.barSpan == BarSpan.Month) {
    months = monthsInSpan({ start: state.start, end: state.end });
  } else {
    switch (props.yearlyView) {
      case YearlyViewBehavior.None:
        return [];
      case YearlyViewBehavior.LastMonth:
        months = getLastMonthOfSelectedSpans(state.barSpan);
        break;
      case YearlyViewBehavior.AccumulateMonths:
        return calculateAccumulatedMonths(state.barSpan);
    }
  }
  return months.map(calculateMonthlyResults);
}

function calculateAccumulatedMonths(span: BarSpan.Year | BarSpan.Quarter): T[] {
  let spans: MonthSpan[];
  if (span == BarSpan.Year) {
    spans = yearsInSpan({ start: state.start, end: state.end });
  } else {
    spans = quartersInSpan({ start: state.start, end: state.end });
  }

  return spans.map(calculateAccumulatedMonthsInSpan);
}

function calculateAccumulatedMonthsInSpan(span: MonthSpan): T {
  const year = yearFromKey(span.end);
  const startMonth = monthFromKey(span.start);
  const endMonth = monthFromKey(span.end);
  const endOfSpanResults = { month: new Date(year, endMonth) } as T;
  const names = props.namesField && new Set<string>();
  for (const field of props.valueFields) {
    setProp(endOfSpanResults, field, 0);
  }
  for (let m = startMonth; m <= endMonth; m++) {
    const month = monthKeyFromYearAndMonth(year, m);
    const monthlyResults = calculateMonthlyResults(month);
    for (const field of props.valueFields) {
      const old = getProp(endOfSpanResults, field);
      const val = getProp(monthlyResults, field);
      setProp(endOfSpanResults, field, old + val);
    }
    if (props.namesField) {
      for (const name of getProp(monthlyResults, props.namesField))
        names?.add(name);
    }
  }
  if (props.namesField && names) {
    const temp = [...names].sort();
    names.clear();
    temp.forEach((name) => names.add(name));
    setProp(endOfSpanResults, props.namesField, names);
  }
  return endOfSpanResults;
}

function getDisplayInfoTagInnerHtml(d: T) {
  const { columns, showMonthlyTotal } = props.tableSettings;
  const keys = getAllProps(columns);
  const prefix = (props.showUnitInBarTooltip && unitPrefix.value) || "";
  const suffix = (props.showUnitInBarTooltip && unitSuffix.value) || "";

  let dateStr: string | number;

  if (showMonthColumn.value) {
    dateStr = d.month.toLocaleDateString(LOCALE_EN, {
      year: "numeric",
      month: "short",
    });
  } else if (showQuarterColumn.value) {
    const quarterStr = Quarter[quarterFromMonth(d.month.getMonth())];
    dateStr = `${quarterStr} ${d.month.getFullYear()}`;
  } else {
    dateStr = d.month.getFullYear();
  }

  if (keys.length == 1) {
    const key = keys[0];
    const value = getCellValue(d, key);
    return `<strong>${dateStr}</strong><br>
    ${prefix !== undefined ? prefix : ""} ${formatCell(
      value
    )} ${suffix} ${getSuffix(d, key)}`;
  }

  const rows = [`<strong>${dateStr}</strong>`];
  for (const header of getAllKeys(columns)) {
    const key = columns[header];
    const value = getCellValue(d, key);
    if (value == 0) {
      continue;
    }
    const row = `<div class='tooltip-row'>
    <div class="tooltip-row-header">${header}</div>
    <div class="tooltip-row-value">
    ${prefix}${formatCell(value)}${suffix} ${getSuffix(d, key)}
    </div>
    <div class="tooltip-row-separator"></div>
    </div>`;
    rows.push(row);
  }
  if (showMonthlyTotal) {
    rows.push(
      `<div class="tooltip-row-total"><strong>
      Total: ${prefix}${formatCell(getRowTotal(d))}${suffix}
      </div>`
    );
  }
  const tooltipNameList = props.tooltipNameListFields;
  if (tooltipNameList) {
    for (const { key, value } of getAllEntries(tooltipNameList)) {
      const names = getProp(d, key);
      if (names.length != 0) {
        rows.push(`<div class="tooltip-row-separator"></div>
        <div class="tooltip-row">${value}: ${names.join(", ")}</div>`);
      }
    }
  }
  const tooltipNumber = props.tooltipNumberFields;
  if (tooltipNumber) {
    for (const { key, value } of getAllEntries(tooltipNumber)) {
      const row = `
    <div class="tooltip-row-separator"></div>
    <div class='tooltip-row'>
    <div class="tooltip-row-header">${value}</div>
    <div class="tooltip-row-value">${getProp(d, key)}</div>
    </div>`;
      rows.push(row);
    }
  }
  return rows.join("<br>");
}

function getSuffix(d: T, col: ColumnDef<T>) {
  return typeof col == "object" ? col.suffix(d) : "";
}

function getLastMonthOfSelectedSpans(span: BarSpan.Year | BarSpan.Quarter) {
  return span == BarSpan.Year
    ? getLastMonthsOfSelectedYears()
    : getLastMonthsOfSelectedQuarters();
}

function getLastMonthsOfSelectedYears(): MonthKey[] {
  const year1 = yearFromKey(state.start);
  const year2 = yearFromKey(state.end);
  const months = new Array<MonthKey>();
  for (let y = year1; y <= year2; y++) {
    months.push(monthKeyFromYearAndMonth(y, Month.Dec));
  }
  return months;
}

function getLastMonthsOfSelectedQuarters(): MonthKey[] {
  const quarterSpan = { start: state.start, end: state.end };
  return quartersInSpan(quarterSpan).map((span) => span.end);
}

function handleCsvExport() {
  const separator = ";";
  const csvTable = new Array<string>();
  const { showMonthlyTotal, accumulatedTotal, columns, fileName, csvInfoRows } =
    props.tableSettings;
  let monthHeader: string[];
  if (showMonthColumn.value) {
    monthHeader = ["Month"];
  } else if (showQuarterColumn.value) {
    monthHeader = ["Quarter"];
  } else {
    monthHeader = [];
  }

  const headers = ["Year", ...monthHeader, ...getAllKeys(columns)];
  if (showMonthlyTotal) {
    headers.push("Total");
  }
  csvTable.push(headers.join(separator) + "\n");

  for (const row of data.value) {
    let monthValue: string[];
    if (showMonthColumn.value) {
      monthValue = [row.month.toLocaleDateString(LOCALE_EN, { month: "long" })];
    } else if (showQuarterColumn.value) {
      monthValue = [Quarter[quarterFromMonth(row.month.getMonth())]];
    } else {
      monthValue = [];
    }
    const values = [
      row.month.getFullYear(),
      ...monthValue,
      ...getAllProps(columns).map((c) =>
        formatCell(getCellValue(row, c), false)
      ),
    ];
    if (showMonthlyTotal) {
      values.push(getRowTotal(row));
    }
    csvTable.push(values.join(separator) + "\n");
  }

  if (accumulatedTotal) {
    const space = showMonthColumn.value || showQuarterColumn.value ? [""] : [];
    const summaryRow = ["Total", ...space, ...getAllProps(totals.value)];
    if (showMonthlyTotal) {
      summaryRow.push(getAbsoluteTotal());
    }
    csvTable.push(summaryRow.join(separator) + "\n");
  }

  if (csvInfoRows) {
    csvTable.push("\n");
    for (const key of getAllKeys(csvInfoRows)) {
      const val = csvInfoRows[key];
      if (val) {
        const value = getProp(props.options, key);
        csvTable.push(`${val.text}:${separator}${value}${val?.unit ?? ""}`);
      }
    }
  }

  const csvBlob = new Blob(csvTable, { type: "text/csv" });
  downloadBlob(csvBlob, `${fileName}.csv`);
  // TODO: append certain diagram options (e.g. Expected Absence) in rows below the csv table
}

const numberOfColumns = computed(() => {
  const columns = Object.keys(props.tableSettings.columns).length;
  const total = props.tableSettings.showMonthlyTotal ? 1 : 0;
  return 2 + columns + total;
});

const showMonthColumn = computed(
  () =>
    state.barSpan == BarSpan.Month ||
    props.yearlyView != YearlyViewBehavior.AccumulateMonths
);

const showQuarterColumn = computed(
  () =>
    state.barSpan == BarSpan.Quarter &&
    props.yearlyView == YearlyViewBehavior.AccumulateMonths
);

const unitSuffix = computed(() =>
  props.suffixFromOptions
    ? props.suffixFromOptions(
        state.corporationId,
        state.consultantGroupId,
        state.currencyId,
        props.options
      )
    : props.suffix
);

const unitPrefix = computed(() =>
  props.prefixFromOptions
    ? props.prefixFromOptions(
        state.corporationId,
        state.consultantGroupId,
        state.currencyId,
        props.options
      )
    : props.prefix
);
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
$row-br: 5px;
$row-even: #f4f4f4;
$rounded-row-left: $row-br 0 0 $row-br;
$rounded-row-right: 0 $row-br $row-br 0;
$row-hover-bgc: rgba(0, 0, 0, 0.12);

h1 {
  margin-top: 5vh;
}

.diagram-header {
  position: relative;
  left: -25.2%;
  font-weight: bold;
}

.clarification-link {
  margin-top: 20px;
  margin-bottom: 30px;
}

@include form-field;

.checkbox {
  @include checkbox;
}

table {
  @include table($margin-bottom: 3vh);
  margin-left: auto;
  margin-right: auto;

  thead {
    @include thead;

    td:first-child {
      width: 0;
    }
  }

  .total-row {
    border-top: groove;
    border-color: $container-bgc;
  }

  tr {
    vertical-align: middle;
    grid-template-columns: repeat(v-bind(numberOfColumns), 1fr);
    display: grid;

    .name-row {
      display: grid;
      grid-column: 1/-1;
    }

    &:nth-child(even) > td {
      background-color: $row-even;
    }

    &:focus,
    &:focus-visible {
      outline: 2px solid $vea-primary-color;
    }

    &:hover > td,
    &:focus-visible > td {
      background-color: $row-hover-bgc;
    }

    th {
      text-align: right;
      padding-left: 20px;

      &.align-left {
        text-align: left;
        padding-right: 20px;
        padding-left: 0px;
      }
    }

    td {
      background-color: $base-color;
      border-bottom-width: 1px;
      border-color: $container-bgc;
      padding-left: 5px;
      padding-right: 5px;
      white-space: nowrap;
      text-align: right;

      &:first-child {
        border-radius: $rounded-row-left;
      }

      &:last-child {
        border-radius: $rounded-row-right;
      }

      &.align-left {
        text-align: left;
      }
    }
  }

  @media (max-width: 500px) {
    margin-left: 5%;
  }
}

#exportTableAsCsv {
  margin-bottom: 3vh;
}

#noDataMessage {
  margin-top: 50px;
}

.newRow {
  width: 100%;

  label {
    margin: 10px;
  }
}
</style>

<style lang="scss">
@import "@/styles/global.scss";

.tag {
  @include tag;

  .tooltip-row {
    font-weight: normal;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
    position: relative;
    padding: 5px;
    margin-bottom: -10px;
    padding-bottom: 10px;

    .tooltip-row-header {
      flex: 1;
      text-align: left;
    }

    .tooltip-row-value {
      flex: 1;
      text-align: right;
      margin-left: 15px;
    }

    .tooltip-row-separator {
      position: absolute;
      bottom: -5px;
      left: 12.5%;
      width: 75%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
