<template>
  <p v-if="state.isLoading" class="text-loading">Calculating...</p>
  <StatisticsDiagram
    v-else
    :can-show-all-corporations="true"
    :options="state.options"
    :yearly-view="YearlyViewBehavior.LastMonth"
    :table-settings="{
      accumulatedTotal: false,
      showMonthlyTotal: true,
      appendUnit: false,
      fileName: 'Number_of_Assignments',
      columns: {
        'Non-Billable': 'nonBillable',
        Internships: 'internships',
        Probable: { key: 'probable', suffix: probableSuffix },
        Signed: { key: 'signed', suffix: signedSuffix },
      },
    }"
    :value-fields="['nonBillable', 'internships', 'probable', 'signed']"
    :tooltipNumberFields="{ uniqueCustomers: 'Unique customers' }"
    :colors="{
      nonBillable: {
        color: DiagramColors.NON_BILLABLE,
        description: 'NON BILLABLE/INTERNAL WORK',
        textBright: true,
      },
      internships: {
        color: DiagramColors.INTERNSHIP,
        description: 'INTERNSHIPS',
        textBright: false,
      },
      probable: {
        color: DiagramColors.PROBABLE_ASSIGNMENT,
        description: 'PROBABLE ASSIGNMENTS',
        textBright: false,
      },
      signed: {
        color: DiagramColors.SIGNED_ASSIGNMENT,
        description: 'ASSIGNMENTS',
        textBright: true,
      },
    }"
    :calculate-month="calculateNumberOfAssignments"
    v-model:canUpdate="state.canUpdate"
    :hide-currency-filter="true"
    suffix=" assignments"
    title="Number of Assignments"
    clarification="Each bar represents the number of assignments that are active on the last day of that period."
  >
    Assignments displayed:
    <div>
      <UnselectableLabel for="include-nonbillable">
        <input
          type="checkbox"
          class="checkbox"
          id="include-nonbillable"
          v-model="state.options.includeNonBillable"
          @click="state.canUpdate = true"
        />
        Non billable/Internal work
      </UnselectableLabel>

      <UnselectableLabel for="include-internships">
        <input
          type="checkbox"
          class="checkbox"
          id="include-internships"
          v-model="state.options.includeInternships"
          @click="state.canUpdate = true"
        />
        Internships
      </UnselectableLabel>

      <UnselectableLabel for="include-probable">
        <input
          type="checkbox"
          class="checkbox"
          id="include-probable"
          v-model="state.options.includeProbable"
          @click="state.canUpdate = true"
        />
        Probable
      </UnselectableLabel>
    </div>
  </StatisticsDiagram>
</template>

<script setup lang="ts">
import UnselectableLabel from "@/components/UnselectableLabel.vue";
import { DiagramColors } from "@/coveragePeriodReport/enum/DiagramColors";
import StatisticsDiagram from "@/diagram/StatisticsDiagram.vue";
import {
  MonthlyAssignmentCounts,
  calculateNumberOfAssignments,
} from "@/diagram/functions/calculateNumberOfAssignments";
import { YearlyViewBehavior } from "@/diagram/types/YearlyViewBehavior";
import store from "@/store";
import { DecimalUtils } from "@/util/DecimalUtils";
import { EventType } from "@/util/EventEmitter";
import { container } from "tsyringe";
import { reactive } from "vue";

const state = reactive({
  isLoading: true,
  data: [],
  options: {
    includeNonBillable: false,
    includeInternships: false,
    includeProbable: true,
  },
  canUpdate: false,
});

store.state.events.subscribeOrRunIfDispatched(
  EventType.FinishedLoadingTableData,
  () => (state.isLoading = false)
);

const { limitDecimalCount } = container.resolve(DecimalUtils);

function signedSuffix(d: MonthlyAssignmentCounts) {
  return d.signed == d.signedFulltime
    ? ""
    : `(${limitDecimalCount(d.signedFulltime, 2)} full time)`;
}

function probableSuffix(d: MonthlyAssignmentCounts) {
  return d.probable == d.probableFulltime
    ? ""
    : `(${limitDecimalCount(d.probableFulltime, 2)} full time)`;
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.checkbox {
  margin-left: 10px;
  margin-right: 5px;
}
</style>
