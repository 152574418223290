import { TableHeaders } from "@/interfaces/TableHeaders";
import { ConsultantDisplayable } from "@/models/displayable/ConsultantDisplayable";

export class ConsultantHeaders implements TableHeaders<ConsultantDisplayable> {
  public readonly name = "User";
  public readonly urlName = "Consultant / Profile";
  public readonly group = "Consultant Group";
  public readonly corporation = "Company";
  public readonly coverage = "Employment Rate";
  public readonly employmentStart = "Employment Start";
  public readonly employmentEnd = "Employment End";
  public readonly keyCompetencies = "Key Competencies";
  public readonly competencies = "Competencies";
  public readonly services = "Services";
  public readonly certificates = "Certificates";
  public readonly aboutMe = "About Me";
  public readonly ambition = "Ambition";
  public readonly files = "Files";
  public readonly urlField = "Urls";
}
