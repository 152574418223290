import { ConsultantData } from "@/store/data/ConsultantData";
import { ConsultantGroupData } from "@/store/data/ConsultantGroupData";
import { RecruitmentData } from "@/store/data/RecruitmentData";
import { DateKey, MonthKey, monthKeyFromDate } from "@/types/DateKey";
import { ValidateNested } from "class-validator";
import { container } from "tsyringe";
import { ConsultantGroup } from "../ConsultantGroup";
import { Recruitment } from "../Recruitment";
import { ConsultantGroupDisplayable } from "./ConsultantGroupDisplayable";
import { DisplayableBase } from "./DisplayableBase";
import { DateField } from "./fields/DateField";
import { DropdownField } from "./fields/DropdownField";
import { NullableNumberField } from "./fields/NullableNumberField";
import { NullableTextField } from "./fields/NullableTextField";
import { NumberField } from "./fields/NumberField";
import { TableField } from "./fields/TableField";
import { DropdownOption } from "./fields/util/DropdownOption";
import { FieldNumberMinMax } from "./fields/validators/FieldPercentageMinMax";
import { FieldUniqueRecruitment } from "./fields/validators/FieldUniqueRecruitment";

export class RecruitmentDisplayable extends DisplayableBase<Recruitment> {
  @ValidateNested()
  consultantGroup: DropdownField<ConsultantGroup, ConsultantGroupDisplayable>;
  @ValidateNested()
  @FieldUniqueRecruitment()
  period: DateField;
  @ValidateNested()
  @FieldNumberMinMax()
  budget: NullableNumberField;
  @ValidateNested()
  @FieldNumberMinMax()
  prediction: NumberField;
  outcome: TableField;
  @ValidateNested()
  comment: NullableTextField;
  hasComment: TableField;

  constructor(recruitment: Recruitment) {
    super();
    const recruitmentData = container.resolve(RecruitmentData);
    const { headers } = recruitmentData;
    const consultantGroupData = container.resolve(ConsultantGroupData);
    const consultants = container.resolve(ConsultantData);

    this.consultantGroup = new DropdownField(
      headers.consultantGroup,
      recruitment.consultantGroupName,
      consultantGroupData,
      new DropdownOption(
        recruitment.consultantGroupId,
        recruitment.consultantGroupName
      )
    );

    this.period = new DateField(
      headers.period,
      monthKeyFromDate(recruitment.period) as DateKey,
      "month"
    );

    this.budget = new NullableNumberField(headers.budget, recruitment.budget);
    this.prediction = new NumberField(
      headers.prediction,
      recruitment.prediction
    );
    this.prediction.setMandatory(false);

    const outcome = recruitment.calcOutcome(consultants.rows);
    this.outcome = new TableField(
      headers.outcome,
      outcome === 0 && new Date(this.period.value) > new Date()
        ? "-"
        : outcome.toString()
    );
    this.comment = new NullableTextField(headers.comment, recruitment.comment, {
      hideInTable: true,
      placeholder: "Rationale, reason for modifying, etc...",
    });
    this.hasComment = new TableField(
      headers.hasComment,
      recruitment.comment ? "💬" : ""
    );
  }

  toModel(recruitment: Recruitment): void {
    recruitment.consultantGroupId = this.consultantGroup.selectedOption.id;
    recruitment.period = `${this.period.modelValue() as MonthKey}-01`;
    let newBudget = 0;
    if (this.budget.modelValue()) newBudget = Number(this.budget.modelValue());
    recruitment.budget = Number(newBudget);
    recruitment.prediction = Number(this.prediction.modelValue());
    recruitment.comment = this.comment.modelValue();
  }
}
