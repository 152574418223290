import { CssColor } from "@/types/CssColor";
import { valuesAre } from "@/types/valuesAre";

export const DiagramColors = valuesAre<CssColor>()({
  WHITE: "white",
  BLACK: "black",
  BACKGROUND: "whitesmoke", //Old gray: #d3d3d3
  UNOCCUPIED: "#feb5da",
  SIGNED_ASSIGNMENT: "#074650",
  PROBABLE_ASSIGNMENT: "#009292",
  PARTTIME_ASSIGNMENT: "#8ac5ff",
  PROBABLE_PARTTIME_ASSIGNMENT: "#b5dafe",
  ABSENCE: "#a76d33",
  INTERNSHIP: "#4ffd4f",
  NON_BILLABLE: "#480091",
  WARNING: "#fe6db5",
  INTERNS: "#98abc5",
  CONSULTANTS: "steelblue",
  POTENTIAL_ASSIGNMENTS: "steelblue",
  LEADERS: "#009292",
  NIS_ASSIGNMENT: "#99ab95",
  NIS_CONSULTANTS: "#99ab95",
  LEADER_AND_NIS: "#feb5da",
});
