import { IsInt, IsOptional, ValidationArguments } from "class-validator";
import { DisplayableFieldOptions } from "./DisplayableField";
import { NullableField } from "./NullableField";
import { FieldType } from "./enum/FieldType";
import { NumberError } from "./error/NumberError";

export class NullableNumberField extends NullableField<number> {
  @IsOptional()
  @IsInt({
    message: (args: ValidationArguments) =>
      (args.object as NullableNumberField)._error.valueError(args.value),
  })
  protected _value: number | null;
  protected _min: number;
  protected _max: number;

  modelValue(): number | null {
    return this._value;
  }

  get min(): number {
    return this._min;
  }

  get max(): number {
    return this._max;
  }

  constructor(
    header: string,
    data: number | null,
    options?: DisplayableFieldOptions
  ) {
    super(header, new NumberError(header), FieldType.NUMERIC, options);
    this._value = data;
    this._min = options?.minNumericValue ?? 0;
    this._max = options?.maxNumericValue ?? 1000000;
  }
}
