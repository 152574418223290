<template>
  <input
    :id="
      thisField.header.toLocaleLowerCase().replace(/[^a-z0-9]/g, '') + '-field'
    "
    type="number"
    class="edit-input"
    :class="hasError && 'error'"
    v-model="thisField.value"
    :size="3"
    :min="thisField.min"
    :max="thisField.max"
    v-on:input="signalInputUpdate()"
  />
</template>

<script setup lang="ts">
import { PercentageField } from "@/models/displayable/fields/PercentageField";
import { computed } from "vue";
const props = defineProps<{
  field: PercentageField;
  hasError?: boolean;
}>();
const emit = defineEmits(["input-updated"]);
const signalInputUpdate = () => emit("input-updated");
const thisField = computed(() => props.field);
</script>
<style scoped lang="scss">
@import "../../styles/global.scss";

input {
  @include input;
}

//Firefox specific bodge in /assignments
@supports (-moz-appearance: none) {
  input {
    width: 70%;
  }
}

.error {
  @include error;
}
</style>
