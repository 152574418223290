import { IsInt, ValidationArguments } from "class-validator";
import { DisplayableField, DisplayableFieldOptions } from "./DisplayableField";
import { FieldType } from "./enum/FieldType";
import { NumberError } from "./error/NumberError";

export class NumberField extends DisplayableField<number, number> {
  @IsInt({
    message: (args: ValidationArguments) =>
      (args.object as NumberField)._error.valueError(args.value),
  })
  protected _value: number;
  protected _mandatory = true;
  protected _min: number;
  protected _max: number;

  get value(): number {
    return this._value;
  }

  set value(input: number) {
    this._value = input;
  }

  modelValue(): number {
    return this._value;
  }

  get mandatory(): boolean {
    return this._mandatory;
  }

  setMandatory(isMandatory: boolean): void {
    this._mandatory = isMandatory;
  }

  get min(): number {
    return this._min;
  }

  get max(): number {
    return this._max;
  }

  constructor(header: string, data: number, options?: DisplayableFieldOptions) {
    super(header, new NumberError(header), FieldType.NUMERIC, options);
    this._value = data;
    this._min = options?.minNumericValue ?? 0;
    this._max = options?.maxNumericValue ?? 1000000;
  }
}
